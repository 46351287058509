
import React from 'react';
import { Layout } from "../Layout";
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import * as common from "../../common";
import { APIResponse, Exam, learner } from "../../react-app-env";
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../StylesUI/CommonLayouts';
import { initialExam } from '../Home';
import { RichEditor } from '../RichEditor/RichEditor'
import { StartScreen } from '../CommonConponents/StartScreen';
import UpdateIcon from '@material-ui/icons/Update';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { QrCode } from '../QrCode';
import ReactPlayer from 'react-player';

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { sendSync, useSync } from '../../sync';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

const useStyles = makeStyles((theme: Theme) =>
({
    previewQuestionRoot: {
        flexGrow: 1,
        fontSize: 14,
    },
    examNameStyle: {
        color: "#003f71",
        fontSize: common.FONT_SIZE.title,
        fontWeight: "bold",
        textAlign: "center"
    },
    hasParentQuestion: {
        paddingLeft: "30px"
    },
    activePage: {
        background: "lightblue"
    },
    btnContainer: {
        display: "flex",
        justifyContent: "center",
        "& button": {
            fontSize: common.FONT_SIZE.button,
            width: "240px",
            minWidth: "120px"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& #disabledBtn": {
            backgroundColor: "#adb5bd",
            border: "solid 1px #adb5bd"
        }
    },
    centerContainer: {
        display: "flex",
        justifyContent: "center",
        "& #countTime": {
            fontSize: "34px",
        },
        "& h2": {
            color: "#003f71",
            fontSize: common.FONT_SIZE.title,
            fontWeight: "bold",
            textAlign: "center"
        },
        "& #watchedBtn": {
            marginBottom: "15px",
            '& .MuiSvgIcon-root ': { fontSize: 28 },
            '& .MuiFormControlLabel-label ': { fontSize: 18 }
        },
        "& #videoContainer": {
            margin: "15px 0px"
        },
        "& #videDescription": {
            textAlign: "center"
        },
        "& .text-danger": {
            marginTop: "5px"
        }

    },
    mainTextStyle: {
        fontSize: common.FONT_SIZE.mainText,
        marginTop: "30px",
        marginBottom: "60px"
    },
    placeCodeStyle: {
        marginTop: "15px",
        marginBottom: "40px",
        "& #label": {
            color: "#003f71",
            paddingRight: "15px"
        }
    },
    remainingTime: {
        display: "inline-block",
        padding: "0 0 0 12px",
        fontSize: common.FONT_SIZE.title,
        fontWeight: "bold",
        color: "#ffffff",
    },
    remainingTimeBox: {
        display: "inline-block",
        margin: "0 40px",
        padding: " 9px 32px 7px",
        backgroundColor: "#4378b6",
        boxShadow: " 4px 4px 8px rgb(0 0 0 / 15%) inset",
        fontSize: common.FONT_SIZE.mainText,
        color: "#ffffff",
        height: " 44px",
        borderRadius: "22px",
    }
}));





export function PCStandbyScreen() {

    // -----共通関数の宣言-----
    const {
        go, // 画面遷移 
        api,  // API呼び出し
        appContext
    } = common.useCommon();



    // -----API-----
    async function getExam(args?: any) {
        return api("/api/l-exam", "GET", args)
    }

    function putExam(args?: any) {
        return api("/api/l-exam", "PUT", args)
    }

    function getLearner(args?: any) {
        return api("/api/l-learner", "GET", args)
    }



    //-----汎用関数の定義-------

    //試験開始状態を更新する
    const putStartProcess = () => {
        putExam({ start: true, placeCode: userPlaceCode, PCStartFlag: true })
            .then((res: any) => {
                if (res.errorCode !== 20000) {
                    common.alertError(res.errorTitle, res.errorDetail);
                    return;
                }

                if (examStateValue.choiceDeviceFlag) {
                    //モバイル端末へ同期送信する
                    setCompleteProcess("start");
                    sendSyncCurrentState();
                }

                go("/pc/home");

            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_PutExam);
            });
    }


    {/* 
  const countDown = (time: number) => {
    let count = time;
    let nowDate = new Date();
      const endDate = new Date(nowDate.getTime() + time * 1000);
    let id = setInterval(() => {
      count--;
      nowDate = new Date();

      if (count <= 60) {
        setCountTime(count);
        settimeFlags({ ...timeFlags, secondsFlag: true })
      }
      else setCountTime(Math.ceil(count / 60));

      if (nowDate.getTime() >= endDate.getTime()) {
        clearInterval(id);
        setCountTime(0);
        if(NoError()) startProcess(); //視聴確認ボタンクリックかつ会場コードOKなら画面遷移
      }
    }, 1000)
    return id;
  } */}



    //試験開始時間を過ぎているかどうか(任意の時用)
    const isAfterStartTime = (startTime: string) => {
        const d1 = new Date();
        const d2 = new Date(startTime);
        const timeDifference = d2.getTime() - d1.getTime();
        if (timeDifference < 0) return true
        return false
    }



    //試験開始状態がDB上で更新されていたら画面遷移
    const startProcess = async () => {

        let loopFlag = true;


        while (loopFlag) {

            await getExam()
                .then((res: APIResponse<Exam>) => {
                    if (res.errorCode !== 20000) {
                        common.alertError(res.errorTitle, res.errorDetail);
                        return;
                    }


                    if (res.value.startStatus === 1 || (res.value.termType === 1 && isAfterStartTime(res.value.executeStart))) {

                        loopFlag = false;

                        getLearner()
                            .then((learnerRes: APIResponse<learner>) => {
                                if (learnerRes.errorCode !== 20000) {
                                    common.alertError(learnerRes.errorTitle, learnerRes.errorDetail);
                                    return;
                                }

                                if (learnerRes.value !== null) {
                                    //モバイルサイトで試験開始ボタンを押しているか ※これは今の実装では必ずfalse
                                    if (learnerRes.value.executionStartDatetime !== null) {

                                        //モバイル端末へ同期送信する
                                        setCompleteProcess("start");
                                        sendSyncCurrentState();

                                        go("/pc/home");

                                    } else {
                                        //試験開始画面がある場合は表示する
                                        if (res.value.startViewFlag) {
                                            setIsShowStartScreen(true);
                                        } else {
                                            putStartProcess();
                                        }
                                    }

                                }
                            })
                            .catch((err: any) => {
                                alert(common.ResponseMessages.Error_GetLearner);
                            });

                    }


                })
                .catch((err: any) => {
                    alert(common.ResponseMessages.Error_GetExam);
                });

        }
    }


    //試験開始画面からHome画面へ戻る処理
    const returnHome = () => {
        go("/");
    }

    //視聴確認チェックボックスクリック時の処理
    const onCheckWatchedBox = () => {
        setWatchedFlag(true)
    }

    //試験開始後に表示される試験開始用ボタンクリック時の処理
    const onCheckStartExamBtn = () => {
        if (NoError()) {
            startProcess();
        }
    }

    //試験会場コード入力時の処理
    const inputPlaceCode = (inputText: string) => {
        if (examStateValue.executionPlaces === null) return;

        setUserPlaceCode(inputText);

        let isPlCdErr = true;
        for (let place of examStateValue.executionPlaces) {
            if (place?.placeCode == inputText) {
                isPlCdErr = false;
                break;
            }
        }
        setIsPlaceCodeErr(isPlCdErr);
    }

    const fullScreen = () => {
        setIsFullScreen(true);
        document.documentElement.requestFullscreen();
    }

    const checkWindowAuthorization = () => {
        common.checkScreenCount().catch((err: any) => {
            if (err.name === "NotAllowedError") {
                common.alertError(
                    "ブラウザの「ウィンドウ管理」を「許可する」に設定してください。",
                    "ウィンドウ管理を許可するには、アドレスバーに表示されているURLの先頭アイコンをクリックし、「ウィンドウ管理」の設定を「許可する」または「確認」に設定してください。"
                );
            }
        });
    }

    //視聴確認済みかつ正しい試験会場コードが入力されているかどうか
    const NoError = () => { return (watchedFlag && !isPlaceCodeErr && isFullScreen) };

    //-----スタイルの宣言-------
    const classNames = useStyles();

    //----stateの定義---------
    const [timeFlags, settimeFlags] = React.useState({ startFlag: false, secondsFlag: false });
    const [countTime, setCountTime] = React.useState<number>(0);
    const [examStateValue, setExamStateValue] = React.useState<Exam>(initialExam);
    const [isShowStartScreen, setIsShowStartScreen] = React.useState(false);
    const [mobileUrl, setMobileUrl] = React.useState("");
    const [watchedFlag, setWatchedFlag] = React.useState(false);
    const [isUnderTest, setIsUnderTest] = React.useState<boolean>(false);
    const [userPlaceCode, setUserPlaceCode] = React.useState("");
    const [isPlaceCodeErr, setIsPlaceCodeErr] = React.useState(false);
    const [time, setTime] = React.useState(0);
    const [isFullScreen, setIsFullScreen] = React.useState(false);

    //同期用
    const [completeProcess, setCompleteProcess] = React.useState<string>("");

    // -----use effefct-----
    React.useEffect(() => {

        //let timerId: any;

        getExam()
            .then((res: APIResponse<Exam>) => {
                if (res.errorCode !== 20000) {
                    common.alertError(res.errorTitle, res.errorDetail);
                    return;
                }

                if (res.value !== null) {
                    //if (res.value.startSeconds !== 0 && res.value.startStatus !== 2) timerId = countDown(res.value.startSeconds);
                    if (res.value.startSeconds !== 0 && res.value.startStatus !== 2) setTime(res.value.startSeconds);
                    setExamStateValue(res.value);

                    //テスト時間中フラグを設定
                    if (res.value.startSeconds === 0 && res.value.startStatus !== 2) setIsUnderTest(true);
                    if (res.value.startStatus === 2) go("/")

                    //試験説明動画URLがない場合は視聴確認チェックボックスのチェックをON
                    if (!res.value.advanceExplanationVideoUrl) setWatchedFlag(true);

                    //試験会場コードある場合はエラーを設定
                    if (res.value.executionPlaces?.length > 0 && userPlaceCode === "") setIsPlaceCodeErr(true);
                }

                getLearner()
                    .then((learnerRes: APIResponse<learner>) => {
                        if (learnerRes.errorCode !== 20000) {
                            common.alertError(learnerRes.errorTitle, learnerRes.errorDetail);
                            return;
                        }

                        if (learnerRes.value !== null) {

                            if (appContext.fakeapi_mode && appContext.fake_synctoken) {
                                // 模擬試験モードでは、飛び先はチュートリアルトップで同期トークン付き
                                //setMobileUrl(window.location.origin + "/tutorial?token=" + appContext.fake_synctoken);
                                // 模擬試験モードでは、飛び先はサンプル試験で同期トークン付き
                                setMobileUrl(window.location.origin + "/tutorial2/guide/exam?token=" + appContext.fake_synctoken);
                            } else {
                                //モバイル端末でログインするためのQRコードのURLに受験者IDをパラメータとして付加
                                const path = window.location.origin + "/Identity/Account/Login?code=" + learnerRes.value.loginCode;
                                setMobileUrl(path);
                            }

                        }

                    })
                    .then(() => {
                        // ウィンドウ管理の許可をチェック
                        if (!appContext.fakeapi_mode) checkWindowAuthorization();
                    })
                    .catch((err: any) => {
                        alert(common.ResponseMessages.Error_GetLearner);
                    });

            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetExam);
            });

        {/*
        return () => {
            clearInterval(timerId);
        } */}

        //}, [watchedFlag, isPlaceCodeErr])
    }, []);

    React.useEffect(() => {
        let nowDate = new Date();
        const endDate = new Date(nowDate.getTime() + time * 1000);
        let id = setInterval(() => {
            setTime(time - 1);
            nowDate = new Date();

            if (document.fullscreenElement !== undefined && document.fullscreenElement !== null) { // HTML5 標準
                setIsFullScreen(true);
            }

            if (time <= 60) {
                setCountTime(time);
                settimeFlags({ ...timeFlags, secondsFlag: true })
            } else {
                setCountTime(Math.ceil(time / 60));
                settimeFlags({ ...timeFlags, secondsFlag: false })
            }

            if (time === 0) {
                clearInterval(id);
                setCountTime(0);
                if (NoError()) startProcess(); //視聴確認ボタンクリックかつ会場コードOKなら画面遷移
                setIsUnderTest(true);
            }

            if (time > 0 && isUnderTest) {
                setIsUnderTest(false);
            }
        }, 1000)
        return () => clearInterval(id);
    }, [time, watchedFlag, isPlaceCodeErr]);


    /**
     * 同期処理初期化
     */
    useSync("PCStandByScreen", false, async (label, data) => {
        // 同期処理受信処理  

    });

    /**
       * 同期処理送信処理
       */
    function sendSyncCurrentState() {
        // stateの変更は非同期なので、
        // 更新後の値で同期する
        setCompleteProcess(_completeProcess => {
            sendSync("PCStandByScreen", {
                completeProcess: _completeProcess
            });
            return _completeProcess;
        });
    }

    // ヘッダーに表示する試験開始までの時間
    let headerRemainingTime = (
        <span className={classNames.remainingTimeBox} hidden={isUnderTest} >
            試験開始まで残り
            <span className={classNames.remainingTime} >
                {countTime} {timeFlags.secondsFlag ? "秒" : "分"}
            </span>
        </span>
    );


    return (
        <Layout viewType="pc" width={"800px"} isTutorial={appContext.fakeapi_mode} headerRemainingTime={headerRemainingTime}>
            {isShowStartScreen ?

                <StartScreen isMobile={false} exam={examStateValue} handleStartClick={putStartProcess} returnHome={returnHome} mobileUrl={mobileUrl} />

                :

                <div>

                    <div className={classNames.centerContainer}>
                        <p hidden={examStateValue.status !== 0} className="text-danger">
                            ただいま準備中です。<br />適宜画面を再読み込みしてください。
                        </p>
                    </div>

                    <div hidden={!examStateValue.choiceDeviceFlag}>
                        <div className={classNames.centerContainer}>
                            <p>この試験では問題文をPCで表示し、解答をモバイル端末で行います。</p>
                        </div>
                        <div className={classNames.centerContainer}>
                            <p>モバイル端末画面を閉じてしまった際は、こちらのQRコードからアクセスして下さい。</p>
                        </div>

                        <div className={classNames.centerContainer}>
                            {mobileUrl ? <p id="qrCode"><QrCode text={mobileUrl} /></p> : undefined}
                        </div>
                    </div>


                    <div className={classNames.centerContainer} hidden={isUnderTest} >

                        <h2> 試験開始まで残り&nbsp;<span id="countTime">{countTime}</span>&nbsp;{timeFlags.secondsFlag ? "秒" : "分"}</h2>

                    </div>

                    <div className={classNames.centerContainer}>

                        {/* <div className={classNames.mainTextStyle}>
              試験問題は開始時間を過ぎると自動的に表示されます。<br />
                  試験開始時間を過ぎても試験問題が表示されない場合は<br />
                  「画面を更新する」ボタンを押してください。
            </div> */}

                        <div className={classNames.mainTextStyle}>

                            <div hidden={!examStateValue.advanceExplanationVideoUrl}>
                                <p id="videDescription">試験前注意事項動画を視聴して下さい。<br />視聴完了後、「視聴確認」チェックボックスをチェックして下さい。</p>

                                <div id="videoContainer" className={classNames.centerContainer} >
                                    <ReactPlayer
                                        //url={"https://www.youtube.com/watch?v=1xP3BgO8UQ4"}
                                        url={examStateValue.advanceExplanationVideoUrl}
                                        playsinline
                                        controls={true}
                                        height="254px"
                                        width="452px"
                                        crossOrigin="anonymous"
                                    />
                                </div>

                                <div className={classNames.centerContainer} >
                                    <FormControlLabel
                                        id="watchedBtn"
                                        hidden={examStateValue.status === 0}
                                        control={
                                            <Checkbox
                                                onChange={onCheckWatchedBox}
                                                defaultChecked={false}
                                                disabled={watchedFlag}
                                            />
                                        }
                                        label={"視聴確認"}
                                    />
                                </div>
                            </div>

                            <div className={classNames.centerContainer} >
                                <ButtonContainer className={classNames.btnContainer} style={{ marginBottom: "10px" }}>
                                    <DefaultButton id={"fullscreenBtn"} onClick={fullScreen}><ZoomOutMapIcon />&nbsp;<span>全画面表示</span></DefaultButton>
                                </ButtonContainer>
                            </div>

                            <div className={classNames.centerContainer + " " + classNames.placeCodeStyle} hidden={!(examStateValue.executionPlaces?.length > 0)}>
                                <p id="label">試験会場コード</p>
                                <input type="text" value={userPlaceCode ?? ""} onChange={(event) => inputPlaceCode(event.target.value)} />
                            </div>

                            <div hidden={isUnderTest} >
                                {examStateValue.startViewFlag
                                    ? "開始時間になると自動的に試験開始画面に遷移します。" //"試験時間を過ぎると自動的に試験開始画面に遷移します。"

                                    : "開始時間になると自動的に試験が開始されます。" //"試験時間を過ぎると自動的に試験問題画面に遷移します。"
                                }
                                <br />
                                {examStateValue.startViewFlag
                                    ? "試験開始画面が自動的に表示されない場合は、ブラウザの再読み込みをお願いします。"

                                    : "試験問題が自動的に表示されない場合は、ブラウザの再読み込みをお願いします。"
                                }
                            </div>
                            <p hidden={isUnderTest} className="text-danger">
                                <div hidden={!examStateValue.advanceExplanationVideoUrl}>
                                    ※「視聴確認」チェックボックスがチェックされていないと遷移しません。
                                </div>
                                <div>
                                    ※「全画面表示」ボタンが押下されていないと遷移しません。
                                </div>
                                <div hidden={!(examStateValue.executionPlaces?.length > 0)}>
                                    ※正しい試験会場コードが入力されていないと遷移しません。
                                </div>
                            </p>


                            <ButtonContainer className={classNames.btnContainer} hidden={!isUnderTest || examStateValue.status === 0} >
                                <DefaultButton id={NoError() ? "" : "disabledBtn"} disabled={!NoError()} onClick={onCheckStartExamBtn}>
                                    {examStateValue.startViewFlag
                                        ? "試験開始画面へ"

                                        : "試験を開始する"
                                    }</DefaultButton>
                            </ButtonContainer>

                            <p hidden={!isUnderTest || examStateValue.status === 0} className="text-danger">
                                <div hidden={!examStateValue.advanceExplanationVideoUrl}>
                                    ※「視聴確認」チェックボックスがチェックされていないとクリック出来ません。
                                </div>
                                <div>
                                    ※「全画面表示」ボタンが押下されていないとクリック出来ません。
                                </div>
                                <div hidden={!(examStateValue.executionPlaces?.length > 0)}>
                                    ※正しい試験会場コードが入力されていないとクリック出来ません。
                                </div>
                            </p>

                        </div>

                    </div>

                    {/* <ButtonContainer className={classNames.centerContainer}>
            <DefaultBackButton className="backBtn" onClick={() => { go("/") }}><ArrowBackIosIcon />&nbsp;<span>戻る</span></DefaultBackButton>
            <DefaultButton id="updateBtn" onClick={() => { window.location.reload() }}><UpdateIcon />&nbsp;<span>画面を更新する</span></DefaultButton>
          </ButtonContainer> */}
                </div>
            }
        </Layout>
    );
}