import { BlockBlobUploadOptions, ContainerClient, HttpRequestBody } from "@azure/storage-blob";
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import ReactPlayer from 'react-player';
import { useCommon } from '../../../common';
import { APIResponse, ISasData, UserData } from '../../../react-app-env';

const MIME_EXT_MAP = [{
    // mp4 for iOS
    mime: `video/mp4;codecs="avc1,mp4a"`, ext: "mp4"
}, {
    // mp4 for other
    mime: `video/mp4;codecs="h264,mp4a"`, ext: "mp4"
}, {
    // webm for PC / android
    mime: `video/webm;codecs="vp8,opus"`, ext: "webm"
},];

// 切り出し期間 30秒
const REC_INTERVAL_MS = 30 * 1000;

export interface IRecordingScreenProps {
    hiddenFlag: boolean;
    onStartError?: (err: any) => void;
    mode?: "default" | "environment";
    anonym?: boolean;
    callback?: (mr: MediaRecorder | null | undefined,
        data?: IMovieBlobData,
        mime?: string,
        ext?: string) => void;
}

interface IRefDara {
    mounted: boolean;
    mr?: MediaRecorder;
}

export interface IMovieBlobData {
    data: Blob;
    from: Date;
    to: Date;
    fromTimeStamp: number;
    toTimeStamp: number;
}

const useStyles = makeStyles((theme: Theme) =>
({
    RecordingScreenRoot: (props: IRecordingScreenProps) => ({
        visibility: props.hiddenFlag ? "hidden" : "visible",
        height: props.hiddenFlag ? "70px" : "auto"
    })
}

));

/**
 * 動画録画・表示・送信コンポーネント
 * @param props 
 * @returns 
 */
export function RecordingScreen(_props: IRecordingScreenProps) {

    const props : IRecordingScreenProps = { ..._props, mode : _props.mode ?? "default"};

    // スタイル
    const classNames = useStyles(props);

    // 動画ストリームを格納するState
    const [stateStream, setStateStream] = React.useState<MediaStream>();

    // 共通関数
    const common = useCommon();

    async function getSasData() {
        const res: APIResponse<ISasData> = await common.backgroud_api("/api/l-storage", "GET");
        return res.value;
    }

    let sasData: ISasData;
    async function uploadBlob(name: string,
        body: HttpRequestBody,
        len: number,
        options: BlockBlobUploadOptions,
        retryCount?: number) {
        if (!sasData || !sasData.sas) {
            sasData = await getSasData();
            if (!sasData || !sasData.sas) {
                throw new Error();
            }
        }
        const client = new ContainerClient(sasData.sas);
        try {
            const result = await client.uploadBlockBlob(name, body, len, options);
            // HTTPステータスコード
            if (result.response._response.status >= 400) {
                // 400以上はエラー
                throw new Error(result.response._response.status.toString());
            }
            // エラーコード
            if (result.response.errorCode) {
                throw new Error(result.response.errorCode);
            }
        } catch (err) {
            if (!retryCount) {
                retryCount = 0;
            }
            if (retryCount <= 3) {
                await uploadBlob(name, body, len, options, retryCount + 1);
            } else {
                throw err;
            }
        }
    }

    // マウント時に実施する副作用
    React.useEffect(() => {

        if (props.hiddenFlag === true) {
            return;
        }

        const refData: IRefDara = {
            mounted: true
        };

        // async構文を使うために関数を定義
        const func = async () => {
            try {
                // カレントユーザー取得
                const user = await common.getUser();
                if (!user || !user.id) {
                    // ログイン前
                    if(!props.anonym){
                        return;
                    }
                }
                // カメラ映像取得
                const stream = await getUserMedia(props);
                if (!stream) {
                    // カメラ取得できず
                    return;
                }
                // DOMにマウントされているか確認
                if (refData.mounted === false) {
                    // 他のページへの遷移後
                    return;
                }
                // 取得した stream を画面に表示
                setStateStream(stream);
                // 録画するメディアタイプと拡張子を決定
                const { mime, ext } = getMediaType();
                // モードで動作を分岐
                if (props.mode === "default") {
                    repeat(async (repeatId) => {
                        if (refData.mounted === false) {
                            clearInterval(repeatId);
                            return;
                        }
                        // intervalのあいだ録画し、録画したデータをblobで取得
                        refData.mr = new MediaRecorder(stream, {
                            mimeType: mime
                        });
                        const res = await recordStream(stream, mime, REC_INTERVAL_MS, refData);

                        // サーバーに送信
                        await uploadBlob(
                            user.id + "/" + res.from.getTime() + "." + ext,
                            res.data,
                            res.data.size,
                            {
                                metadata: {
                                    UserId: user.id,
                                    From: res.from.toISOString(),
                                    To: res.to.toISOString(),
                                    FromTimeStamp: res.fromTimeStamp.toString(),
                                    ToTimeStamp: res.toTimeStamp.toString()
                                }
                            });
                    }, REC_INTERVAL_MS);
                } else {
                    refData.mr = new MediaRecorder(stream, {
                        mimeType: mime
                    });
                    if (props.callback) {
                        props.callback(refData.mr);
                    }
                    const res = await recordStream(stream, mime, undefined, refData);
                    if (props.callback) {
                        props.callback(refData.mr, res, mime, ext);
                    }
                }
            } catch (err) {
                if (props.onStartError) {
                    props.onStartError(err);
                }
            }
        };

        func();

        return () => {
            try {
                refData.mounted = false;
                if (refData.mr && refData.mr.state === "recording") {
                    refData.mr.stop();
                }
                if (stateStream) {
                    const tracks = stateStream.getTracks();
                    tracks.forEach(function (track) {
                        track.stop();
                    });
                }
            } catch (e) {

            }
        };

    }, [props.hiddenFlag]);

    return (
        <div className={classNames.RecordingScreenRoot}>
            {
                props.hiddenFlag ? <></>
                    :
            <ReactPlayer
                url={stateStream}
                id="MainPlay"
                playing
                muted
                playsinline
                controls={false}
                height="auto"
                width="100%"
            />
            }
        </div>
    );
}

function repeat(callback: (id?: number) => any, interval: number) {
    callback();
    const id = window.setInterval(() => {
        callback(id);
    }, interval);
}

function getMediaType() {
    if (!MediaRecorder) {
        throw new Error("not supported:MediaRecorder");
    }

    let mime: string = "";
    let ext: string = "";
    MIME_EXT_MAP.forEach((x) => {
        if (mime) {
            return;
        }
        if (MediaRecorder.isTypeSupported(x.mime)) {
            mime = x.mime;
            ext = x.ext;
        }
    });

    if (!mime || mime.length === 0) {
        throw new Error("ブラウザが所定の録画方式に対応していません");
    }
    return { mime, ext };
}

/**
 * カメラストリーム取得をPromiseでラップする関数
 * @returns 
 */
async function getUserMedia(props: IRecordingScreenProps) {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            // インカム限定
            video: {
                facingMode: { ideal: props.mode == "default" ? "user" : "environment" },
                width: 320/*240*/,
                height: 240/*320*/, //カメラ解像度を指定
            }
        });
        if (!stream) {
            throw new Error();
        }
        return stream;
    } catch (err) {
        throw new Error("動画撮影が開始できませんでした。");
    }
}

/**
 * 指定の時間、ストリームを録画する
 * @param stream 
 * @param msec 
 * @returns 
 */
function recordStream(stream: MediaStream, mime: string, msec: number | undefined, refData: IRefDara) {
    // console.log(stream.getVideoTracks()[0].getCapabilities());
    return new Promise<IMovieBlobData>((resolve, reject) => {
        const mediaRecorder = refData.mr as MediaRecorder;
        let timeOrigin: number;
        let chunks: Blob[];
        let fromDate: Date;
        let fromTimeStamp: number;
        mediaRecorder.onstart = function (e) {
            fromTimeStamp = e.timeStamp;
            fromDate = new Date();
            // timestampの起点を計算する
            timeOrigin = (fromDate.getTime()) - e.timeStamp;
            if (window.performance && window.performance.timeOrigin) {
                // console.log(timeOrigin);
                timeOrigin = window.performance.timeOrigin;
                // console.log(timeOrigin);
            }
            chunks = [];
        };
        mediaRecorder.ondataavailable = function (e: BlobEvent) {
            if (!chunks) {
                return;
            }
            chunks.push(e.data);
        };
        mediaRecorder.onstop = (e) => {
            if (!chunks) {
                return;
            }
            // ストップした時点のデータでresolveする
            const toTimeStamp = e.timeStamp;
            const toDate = new Date(timeOrigin + e.timeStamp);
            const blob = new Blob(chunks, { type: mime });
            resolve({
                data: blob,
                from: fromDate,
                to: toDate,
                fromTimeStamp: fromTimeStamp,
                toTimeStamp: toTimeStamp
            });
        };
        mediaRecorder.onerror = (e) => {
            reject((e as any).error || e.type);
        };
        // 録画開始
        mediaRecorder.start();
        // msecが指定されていれば、msec後に自動で止める
        if (msec && isFinite(msec)) {
            setTimeout(() => {
                if (mediaRecorder && mediaRecorder.state === "recording") {
                    mediaRecorder.stop();
                }
            }, msec);
        }
    });
}
