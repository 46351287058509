
import { BlockBlobUploadOptions, ContainerClient, HttpRequestBody } from '@azure/storage-blob';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import ReactPlayer from 'react-player';
import * as common from "../../common";
import { APIResponse, ISasData, UserData } from "../../react-app-env";
import { Layout } from "../Layout";
import { DefaultBackButton, DefaultButton } from '../StylesUI/CommonLayouts';
import { IMovieBlobData, RecordingScreen } from './Exam/RecordingScreen';
import { sendSync, useSync } from '../../sync';

const useStyles = makeStyles((theme: Theme) =>
({
    root: {
        padding: "20px",
        "& h1": {

        }
    },
    videoArea: {
        width: "100%",
    },
    mainVideo: {
        margin: "0 auto",
        width: "240px",//"100%",
        height: "320px",
    },
    photoImage: {
        width: "100%"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        marginTop: "10px",
        "& button": {
            width: "100px"
        }
    }
}));

enum steps {
    explain,
    taking,
    confirm
}

/**
 * 360度周囲動画撮影画面
 */
export function MobileEnvironmentMovie() {
    const {
        go, // 画面遷移 
        api,  // API呼び出し
        backgroud_api,
        setAppContext
    } = common.useCommon();

    // スタイル
    const classNames = useStyles();

    // 動画データを格納するState
    const [blobData, setBlobData] = React.useState<IMovieBlobData>();

    // 現在のステップを格納するState
    const [currentStep, setCurrentStep] = React.useState(steps.explain);

    const [user, setUser] = React.useState<UserData>();

    const [fileExt, setFileExt] = React.useState<string>();

    //同期用
    const [completeProcess, setCompleteProcess] = React.useState<string>("");

    // recorder ref
    const mrRef = React.useRef<MediaRecorder | null>();

    async function getSasData() {
        const res: APIResponse<ISasData> = await backgroud_api("/api/l-storage", "GET");
        return res.value;
    }

    async function postEnvMovie(blobUrl: string) {
        return await backgroud_api<{ blobUrl: string }, APIResponse<any>>(
            "/api/l-env_movie", "POST", { blobUrl });
    }

    let sasData: ISasData;
    async function uploadBlob(name: string,
        body: HttpRequestBody,
        len: number,
        options: BlockBlobUploadOptions,
        retryCount?: number): Promise<string> {
        if (!sasData || !sasData.sas) {
            sasData = await getSasData();
            if (!sasData || !sasData.sas) {
                throw new Error();
            }
        }
        const client = new ContainerClient(sasData.sas);
        try {
            const result = await client.uploadBlockBlob(name, body, len, options);
            // HTTPステータスコード
            if (result.response._response.status >= 400) {
                // 400以上はエラー
                throw new Error(result.response._response.status.toString());
            }
            // エラーコード
            if (result.response.errorCode) {
                throw new Error(result.response.errorCode);
            }
            return result.blockBlobClient.url;
        } catch (err) {
            if (!retryCount) {
                retryCount = 0;
            }
            if (retryCount <= 3) {
                return await uploadBlob(name, body, len, options, retryCount + 1);
            } else {
                throw err;
            }
        }
    }

    // メモリーリーク対応
    const blobURLRef = React.useRef<string | undefined>(undefined);
    blobURLRef.current = React.useMemo<string | undefined>(() => {
        try {
            // console.log("update blobURL");
            // console.log(blobURLRef.current);
            if (blobURLRef.current) {
                URL.revokeObjectURL(blobURLRef.current);
            }
        } catch (err) {

        }
        if (blobData?.data != null) {
            return URL.createObjectURL(blobData.data);
        } else {
            return undefined;
        }
    }, [blobData?.data]);
    const blobURL = blobURLRef.current;

    // 初期処理
    React.useEffect(() => {
        common.getUser().then((u) => {
            setUser(u);
        });
        return () => {
            if (blobURLRef.current) {
                URL.revokeObjectURL(blobURLRef.current);
            }
        };
    }, []);

    /**
   * 同期処理初期化
   */
    useSync("MobileHomeEnvironmentMovie", true, async (label, data) => {
        // 同期処理受信処理  

    });

    /**
     * 同期処理送信処理
     */
    function sendSyncCurrentState() {
        // stateの変更は非同期なので、
        // 更新後の値で同期する
        setCompleteProcess(_completeProcess => {
            sendSync("MobileHomeEnvironmentMovie", {
                completeProcess: _completeProcess
            });
            return _completeProcess;
        });
    }

    return (
        <Layout viewType="mobile">
            <div className={classNames.root}>
                <div hidden={currentStep !== steps.explain}>
                    <h1>
                        <p>不正防止のため、周囲の動画撮影を行います。</p>
                        <p>
                            参考書や受験者以外の人物の立ち入りなどがないことを確認してください。
                        </p>
                        <p>
                            撮影の準備ができたら「録画開始」で開始してください。
                        </p>
                    </h1>

                    <div className={classNames.buttonContainer}>
                        {/* 録画せず戻ることはないので、「戻る」ボタン削除
                            <DefaultBackButton onClick={() => {
                            go("/home");
                        }}>
                            戻る
                        </DefaultBackButton>*/}
                        <DefaultButton onClick={() => {
                            setCurrentStep(steps.taking);
                        }} >
                            録画開始
                        </DefaultButton>
                    </div>
                </div>
                <div hidden={currentStep !== steps.taking}>
                    <h1>
                        <p>
                            周囲が３６０°すべて映るように、ゆっくりと一周し終わったら、「録画完了」で撮影を完了してください。
                        </p>
                    </h1>
                    <div className={classNames.videoArea}>
                        <div className={classNames.mainVideo}>
                            <RecordingScreen
                                key={currentStep}
                                hiddenFlag={false}
                                onStartError={(err) => {
                                    console.error(err);
                                }}
                                mode="environment"
                                callback={(mr, data, mime, ext) => {
                                    mrRef.current = mr;
                                    if (data) {
                                        setBlobData(data);
                                        setFileExt(ext);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={classNames.buttonContainer}>
                        <DefaultBackButton onClick={() => {
                            setCurrentStep(steps.explain);
                        }}>
                            戻る
                        </DefaultBackButton>
                        <DefaultButton onClick={async () => {
                            if (mrRef.current?.state == "recording") {
                                mrRef.current?.stop();
                            }
                            setCurrentStep(steps.confirm);
                        }} >
                            録画完了
                        </DefaultButton>
                    </div>

                </div>
                <div hidden={currentStep !== steps.confirm}>
                    <h1>
                        <p>「再生」ボタンで送信する動画を確認します。</p>
                        <p>受験する部屋の全体が映っていることを確認してください。</p>
                        <p>取り直す場合は、「戻る」で再撮影できます。</p>
                        <p>
                            よろしければ「送信」でデータを送信してください。
                        </p>
                    </h1>
                    <div className={classNames.videoArea}>
                        <div className={classNames.mainVideo}>
                            <ReactPlayer
                                url={blobURL}
                                id="MainPlay"
                                playsinline
                                controls={true}
                                height="100%"//"auto"
                                width="auto"//"100%"
                                config={{
                                    file: {
                                        forceVideo: true,
                                    }
                                }}
                                onError={(error, data) => {
                                    console.log(error);
                                    console.log(data);
                                }}
                            />
                        </div>
                    </div>
                    <div className={classNames.buttonContainer}>
                        <DefaultBackButton onClick={() => {
                            setCurrentStep(steps.taking);
                        }}>
                            戻る
                        </DefaultBackButton>
                        <DefaultButton onClick={async () => {

                            setAppContext(c => { 
                                return { ...c, backdropIsopen: true };
                            });

                            if (!blobData || !user || !fileExt) {
                                return;
                            }
                            // サーバーに送信
                            try {
                                const blob_url = await uploadBlob(
                                    "env/" + user.id + "." + fileExt,
                                    blobData.data,
                                    blobData.data.size,
                                    {
                                        metadata: {
                                            UserId: user.id,
                                            From: blobData.from.toISOString(),
                                            To: blobData.to.toISOString(),
                                            FromTimeStamp: blobData.fromTimeStamp.toString(),
                                            ToTimeStamp: blobData.toTimeStamp.toString()
                                        }
                                    });

                                // サーバーに反映
                                await postEnvMovie(blob_url);

                                setAppContext(c => {
                                    return { ...c, backdropIsopen: false };
                                });

                                if (window.confirm("動画を送信しました。　撮り直す場合はキャンセルを押してください。")) {
                                    setCompleteProcess("camera");
                                    sendSyncCurrentState();
                                    go("/");
                                } else {
                                    setCurrentStep(steps.taking);
                                }

                            } catch (err) {
                                setAppContext(c => {
                                    return { ...c, backdropIsopen: false };
                                });
                                common.alertError("データ送信失敗",
                                    "送信に失敗しました。時間を置いてやり直してください");
                                setCurrentStep(steps.taking);
                            }
                        }} >
                            送信
                        </DefaultButton>

                    </div>
                </div>
            </div>
        </Layout>
    );
}