import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { Slider } from "@material-ui/core";
import MediaQuery from "react-responsive";

import { Layout } from "../Layout";
import { QuestionContents } from "./Exam/Question";
import { EndScreen } from "./Exam/EndScreen";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ReactLoading from "react-loading";

import {
    DefaultButton,
    ButtonContainer,
    DefaultBackButton,
} from "../StylesUI/CommonLayouts";
import { initialExam } from "../Home";
import * as common from "../../common";
import {
    learner,
    ExpansionExamQuestions,
    APIResponse,
    Exam,
    ExamQuestions,
    Question,
    Choice,
    Figure,
    hiddenFlags,
    PostQuestionArgs,
    IActiveState,
    IExamExtensions,
    ITimeState,
    IInLogs
} from "../../react-app-env";
import { CustomModal } from "../StylesUI/CustomModal";
import { ExtCode, InLogsCode } from "../../common";
import { sendSync, useSync } from "../../sync";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

const useStyles = makeStyles((theme: Theme) => ({
    previewQuestionRoot: {
        flexGrow: 1,
        fontSize: common.FONT_SIZE.mainText,
    },
    sideBarTextStyle: {
        padding: "8px",
        paddingLeft: "24px",
        color: "#003f71",
        fontSize: common.FONT_SIZE.sideBarText,
        lineBreak: "anywhere",
    },
    sideBarTextStyle_minWidth: {
        padding: "4px",
        color: "#003f71",
        fontSize: common.FONT_SIZE.sideBarText,
        lineBreak: "anywhere",
    },
    activePage: {
        background: "#7fb911",
        "& *": {
            color: "white",
        },
    },
    notActivePage: {
        "& > :hover": {
            background: "rgba(127,185,17,.3)",
        },
    },
    readPage: {
        background: "#FFA500",
    },
    answeredPage: {
        background: "rgba(100, 149, 237, 1)",
    },
    readOnlyPage: {
        background: "#808080",
    },
    sideBarButtonPosition: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        "& button": {
            fontSize: "1.4rem",
            width: "180px ",
            padding: "0 1.6rem",
            marginTop: "45px",
            marginLeft: "20px",
            height: "40px",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& #sideBar_warningText": {
            fontSize: "1.1rem",
            marginLeft: "20px",
            color: "#c80e49",
        },
    },
    sideBarListStyle: {
        maxWidth: "240px",
    },
    remainingTime: {
        display: "inline-block",
        padding: "0 0 0 12px",
        fontSize: common.FONT_SIZE.title,
        fontWeight: "bold",
        color: "#ffffff",
    },
    remainingTimeBox: {
        display: "inline-block",
        margin: "0 10px",
        padding: " 9px 32px 7px",
        backgroundColor: "#4378b6",
        boxShadow: " 4px 4px 8px rgb(0 0 0 / 15%) inset",
        fontSize: common.FONT_SIZE.mainText,
        color: "#ffffff",
        height: " 44px",
        borderRadius: "22px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        "& button": {
            fontSize: common.FONT_SIZE.mainText,
            width: "240px",
            minWidth: "120px",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white",
        },
        "& span": {
            paddingBottom: "2px",
        },
    },
    modalText: {
        "& #simple-modal-title": {
            fontWeight: "bold",
            fontSize: "1.6rem",
            margin: "1rem auto 0",
            textAlign: "center",
        },
        "& #warning": {
            fontWeight: "bold",
            color: "red",
            fontSize: "1.6rem",
            margin: "1rem auto 3rem",
            textAlign: "center",
        },
    },
    finishedModalText: {
        "& .modaltext": {
            fontSize: "2rem",
            fontWeight: "bold",
            margin: "1rem auto",
            textAlign: "center",
        },
    },
    loadingStyle: {
        margin: "auto",
    },
    sideBarDescription: {
        fontSize: "1.3rem",
        marginLeft: "1rem",
        "& p": {
            margin: "0.4rem",
            marginLeft: "1rem",
        },
        "& #blue": {
            color: "rgba(100, 149, 237, 1)",
        },
        "& #orange": {
            color: "#FFA500",
        },
        "& #green": {
            color: "#7fb911",
        },
        "& #gray": {
            color: "#808080",
        },
        "& #sideBar_warningText": {
            fontSize: "1.1rem",
            marginLeft: "0",
            color: "#c80e49",
        },
    },
    fullButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginRight: "40px",
        "& button": {
            fontSize: common.FONT_SIZE.mainText,
            width: "44px",
            height: "44px",
            padding: "6px 6px"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white",
        }
    }
}));

//各stateの初期値

const initialExamQuestions: ExpansionExamQuestions[] = [
    {
        id: 999999,
        page: 2,
        sectionFlag: true,
        parentQuestionId: 0,
        subjectText: "初期値2",
        answerStatus: 0,
        selectedChoices: [0],
        answerText: "",
        readonlyFlag: false,
    },
    {
        id: 888888,
        page: 3,
        sectionFlag: false,
        parentQuestionId: 999999,
        subjectText: "初期値3",
        answerStatus: 0,
        selectedChoices: [0],
        answerText: "",
        readonlyFlag: false,
    },
    {
        id: 777777,
        page: 1,
        sectionFlag: false,
        parentQuestionId: 0,
        subjectText: "初期値1",
        answerStatus: 0,
        selectedChoices: [0],
        answerText: "",
        readonlyFlag: false,
    },
];

const initialExamQuestion: ExamQuestions = {
    id: 999999,
    page: 2,
    sectionFlag: true,
    parentQuestionId: 0,
    subjectText: "初期値",
    answerStatus: 0,
};

const initialChoice: Choice[] = [
    {
        id: 6,
        viewOrder: 1,
        bodyText: "サンプル１",
        selected: false,
    },
    {
        id: 5,
        viewOrder: 2,
        bodyText: "サンプル２",
        selected: false,
    },
    {
        id: 4,
        viewOrder: 3,
        bodyText: "サンプル３",
        selected: false,
    },
];

const initialFigure: Figure = {
    id: 999999,
    body: "初期値",
};

const initialQuestion: Question = {
    id: 777777,
    sectionFlag: false,
    parentQuestionId: 999999,
    parentText: "初期値",
    parentHtml: "初期値",
    subjectText: "初期値",
    bodyHtml:
        '{"blocks":[{"key":"bp2bt","text":"日本で一番大きい山は？。","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    answerType: 0,
    choiceShuffleFlag: false,
    choiceNumberingType: 999999,
    choices: initialChoice,
    answerText: "",
    figures: [initialFigure],
    page: 1,
    pageMax: 5,
};

// 退席確認間隔（MS）
const POLLING_INTERVAL_MS = 5000;

//--------カスタマイズしたmaterialUIのコンポーネント--------------
const PrettoSlider = withStyles({
    root: {
        color: "#003f7",
        height: 8,
        padding: 0,
        verticalAlign: "top",
        cursor: "default",
    },
    thumb: {
        height: 34,
        width: 34,
        backgroundColor: "#7fb911",
        marginTop: -12,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 12,
    },
    rail: {
        height: 12,
    },
})(Slider);

export function PCHome() {
    // -----共通関数の宣言-----
    const {
        go, // 画面遷移
        api, // API呼び出し
        backgroud_api,
        appContext,
    } = common.useCommon();

    // -----API-----
    function getExam(args?: any) {
        return api("/api/l-exam", "GET", args);
    }

    //任意開始タイプの試験で、試験開始、終了状態を更新する
    function putExam(args?: any) {
        return api("/api/l-exam", "PUT", args);
    }

    //question一個だけ取得
    function getQuestion(args?: any) {
        return api("/api/l-question", "GET", args);
    }

    //解答を保存
    function postQuestion(args?: any) {
        return api("/api/l-question", "POST", args);
    }

    const getLearner = React.useCallback(
        (args?: any, bBackground = false) => {
            if (bBackground) {
                return backgroud_api<any, APIResponse<learner>>(
                    "/api/l-learner",
                    "GET",
                    args
                );
            }
            return api<any, APIResponse<learner>>(
                "/api/l-learner",
                "GET",
                args
            );
        },
        [backgroud_api, api]
    );

    // 退席状態更新
    async function postLeave(leave: boolean) {
        return api<any, APIResponse<null>>("/api/l-leave", "POST", { leave });
    }

    // 画面接続不正を判定
    async function postScreenCheat(args?: any) {
        //return api("/api/l-screen_cheat", "POST", args);
        return backgroud_api("/api/l-screen_cheat", "POST", args);
    }

    // 不正を登録
    async function postCheat(args?: any) {
        //return api("/api/l-cheat_log", "POST", args);
        return backgroud_api("/api/l-cheat_log", "POST", args);
    }

    // 内部ログを登録
    async function postInLogs(logs: IInLogs[]) {
        //return api("/api/l-in_logs", "POST", logs);
        return backgroud_api("/api/l-in_logs", "POST", logs);
    }

    // -----Handler-----
    //選択肢クリック時の処理
    const handleChange = (choiceId: number) => {
        let questions: ExpansionExamQuestions[] = [];

        if (activeState.question.answerType === 0) {
            questions = state.questionsArray.map((question, index) => {
                if (question.id === activeState.question.id) {
                    question.selectedChoices.splice(0);
                    question.selectedChoices.push(choiceId);
                    return question;
                } else return question;
            });
        }

        if (activeState.question.answerType === 1) {
            questions = state.questionsArray.map((question, index) => {
                if (question.id === activeState.question.id) {
                    if (
                        question.selectedChoices.filter((s) => s === choiceId)
                            .length > 0
                    ) {
                        const i = question.selectedChoices.findIndex(
                            (i) => i === choiceId
                        );
                        question.selectedChoices.splice(i, 1);
                        return question;
                    } else {
                        question.selectedChoices.push(choiceId);
                        return question;
                    }
                } else return question;
            });
        }

        setState({ ...state, questionsArray: questions });
    };

    // 解答テキスト入力時の処理
    const handleTextChange = (inputText: string) => {
        let questions: ExpansionExamQuestions[] = [];

        questions = state.questionsArray.map((question, index) => {
            if (question.id === activeState.question.id) {
                question.answerText = inputText;
                return question;
            } else return question;
        });

        setState({ ...state, questionsArray: questions });
    };

    //セクション問題文のアコーディオンクリック時の処理
    const handleChangeExpansion = () => {
        setIsExpanded(isExpanded ? false : true);
    };

    //モバイル分離時の[次の問題へ]ボタンクリック時の処理
    const nextProcessAtChoiceDevice = () => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "「次へ」ボタン")];

        returnHomeIfNeeded()
            .then((isTested: boolean | void) => {
                const key = activeState.itemKey + 1;

                if (!isTested) {
                    //試験残り時間が無くなったか判定
                    if (isFinishedProcess()) return;

                    updateExamData().then((examValue: any) => {
                        if (examValue !== (null || undefined)) {
                            changeActiveQuestion(key, examValue).then(() => {
                                // 同期送信処理
                                sendSyncCurrentState();
                                inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + key));
                            }).then(() => postInLogs(inLogDatas));
                            //既読処理
                            questionReadProcess(key, examValue);
                        }
                    });

                    // ここから終了画面へは行かないため削除
                    /*if (
                        state.examStateValue.endViewFlag &&
                        key === state.examStateValue.pageMax - 1
                    ) {
                        handleEndByViewDevice();
                    }*/
                    }
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });
    };

    //[次の問題へ]ボタンクリック時の処理
    const handleClickOnNext = (data: PostQuestionArgs) => {
        if (state.examStateValue.choiceDeviceFlag && !isTested) {
            nextProcessAtChoiceDevice();
            return;
        }

        const key = activeState.itemKey + 1;

        //試験終了後に閲覧中の場合
        if (isTested) {
            //changeActiveQuestion(key, state.examStateValue);
            changeActiveQuestion_afterRead(key, state.examStateValue);
            return;
        }

        if (!isTested) {
            //試験残り時間が無くなったか判定、その場合の処理
            if (isFinishedProcess()) return;
        }

        //セクションの時,選択肢が未選択,解答テキストが未入力の時は保存しない
        if (
            !activeState.question.sectionFlag &&
            (data.choices.length !== 0 || data.answerText !== "")
        ) {
            autoSaveAnswer(data, false)?.then(() => {
                //未既読状態を更新する
                updateExamData().then((examValue: any) => {
                    if (examValue !== (null || undefined)) {
                        changeActiveQuestion(key, examValue);
                        //既読処理
                        questionReadProcess(key, examValue);
                    }
                });
            });
        } else {
            //未既読状態を更新する
            updateExamData().then((examValue: any) => {
                if (examValue !== (null || undefined)) {
                    changeActiveQuestion(key, examValue);
                    //既読処理
                    questionReadProcess(key, examValue);
                }
            });
        }

        // ここから終了画面へは行かないため削除
        /*if (
            state.examStateValue.endViewFlag &&
            key === state.examStateValue.pageMax - 1
        ) {
            //if (state.examStateValue.choiceDeviceFlag) handleEndByViewDevice();
            setActiveState((s) => {
                return { ...s, contents: "end-screen" };
            });
        }*/
    };

    //モバイル分離時の[前の問題へ]ボタンクリック時の処理
    const returnProcessAtChoiceDevice = () => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "「前へ」ボタン")];

        returnHomeIfNeeded()
            .then((isTested: boolean | void) => {
                const key = activeState.itemKey - 1;

                if (!isTested) {
                    //試験残り時間が無くなったか判定
                    if (isFinishedProcess()) return;

                    updateExamData().then((examValue: any) => {
                        if (examValue !== (null || undefined)) {
                            changeActiveQuestion(key, examValue).then(() => {
                                // 同期送信処理
                                sendSyncCurrentState();
                                inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + key));
                            }).then(() => postInLogs(inLogDatas));
                            //既読処理
                            questionReadProcess(key, examValue);
                        }
                    });

                    // 終了画面からここへは行かないため削除
                    /*if (key === state.examStateValue.pageMax - 2) {
                        setActiveState((s) => {
                            return { ...s, contents: "Questions" };
                        });
                    }*/
                    }
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });
    };

    //[前の問題へ]ボタンクリック時の処理
    const handleClickOnReturn = (data: PostQuestionArgs) => {
        if (state.examStateValue.choiceDeviceFlag && !isTested) {
            returnProcessAtChoiceDevice();
            return;
        }

        const key = activeState.itemKey - 1;

        //試験終了後に閲覧中の場合
        if (isTested) {
            //changeActiveQuestion(key, state.examStateValue);
            changeActiveQuestion_afterRead(key, state.examStateValue);
            return;
        }

        if (!isTested) {
            //試験残り時間が無くなったか判定、その場合の処理
            if (isFinishedProcess()) return;
        }

        //セクションの時,終了画面,選択肢が未選択,解答テキストが未入力の時では保存しない
        if (
            !activeState.question.sectionFlag &&
            !(
                state.examStateValue.endViewFlag &&
                activeState.itemKey === state.examStateValue.pageMax - 1
            ) &&
            (data.choices.length !== 0 || data.answerText !== "")
        ) {
            autoSaveAnswer(data, false)?.then(() => {
                //未既読状態を更新する
                updateExamData().then((examValue: any) => {
                    if (examValue !== (null || undefined)) {
                        changeActiveQuestion(key, examValue);
                        //既読処理
                        questionReadProcess(key, examValue);
                    }
                });
            });
        } else {
            //changeActiveQuestion(key, state.examStateValue);
            //未既読状態を更新する
            updateExamData().then((examValue: any) => {
                if (examValue !== (null || undefined)) {
                    changeActiveQuestion(key, examValue);
                    //既読処理
                    questionReadProcess(key, examValue);
                }
            });
        }

        // 終了画面からここへは行かないため削除
        /*if (key === state.examStateValue.pageMax - 2) {
            setActiveState((s) => {
                return { ...s, contents: "Questions" };
            });
        }*/
    };

    //試験終了画面で[解答へ戻る]ボタンクリック時の処理
    const handleClickOnReturn_EndScreen = () => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "「解答へ戻る」ボタン")];

        const key = activeState.itemKey - 1;
        //未既読状態を更新する
        updateExamData().then((examValue: any) => {
            if (examValue !== (null || undefined)) {
                changeActiveQuestion(key, examValue, "Questions").then(() => {
                    if (state.examStateValue.choiceDeviceFlag) {
                        // 同期送信処理
                        sendSyncCurrentState();
                        inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + key));
                    }
                }).then(() => {
                    if (state.examStateValue.choiceDeviceFlag) postInLogs(inLogDatas);
                });
                //既読処理
                questionReadProcess(key, examValue);
            }
        });
    };

    //モバイル分離時のサイドバーボタンクリック時の処理
    const sideBarProcessAtChoiceDevice = (page: number) => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "サイドバーボタン")];

        returnHomeIfNeeded()
            .then((isTested: boolean | void) => {
                if (!isTested) {
                    //試験残り時間が無くなったか判定
                    if (isFinishedProcess()) return;

                    updateExamData().then((examValue: any) => {
                        if (examValue !== (null || undefined)) {
                            changeActiveQuestion(page, examValue).then(() => {
                                // 同期送信処理
                                sendSyncCurrentState();
                                inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + page));
                            }).then(() => postInLogs(inLogDatas));
                            //既読処理
                            questionReadProcess(page, examValue);
                        }
                    });
                }
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });
    };

    //サイドバークリック時の処理
    const handleClickOnSideBar = (page: number) => {
        if (state.examStateValue.choiceDeviceFlag && !isTested) {
            sideBarProcessAtChoiceDevice(page);
            return;
        }

        //試験終了後に閲覧中の場合
        if (isTested) {
            //changeActiveQuestion(page, state.examStateValue);
            changeActiveQuestion_afterRead(page, state.examStateValue);
            return;
        }

        const answerTextData =
            state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? "";
        const data: PostQuestionArgs = {
            id: activeState.question.id,
            choices: state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices.map((id) => ({ choiceId: id })) ?? [],
            answerText: answerTextData,
        };

        //試験残り時間が無くなったか判定、その場合の処理
        if (isFinishedProcess()) return;

        //セクションの時,終了画面,選択肢が未選択,解答テキストが未入力の時では保存しない
        //サイドバーで今表示している問題をクリックした時に、選択肢が正しく表示されるようにしている
        if (
            !activeState.question.sectionFlag &&
            !(
                state.examStateValue.endViewFlag &&
                activeState.itemKey === state.examStateValue.pageMax - 1
            ) &&
            (data.choices.length !== 0 || data.answerText !== "")
        ) {
            autoSaveAnswer(data, false)?.then(() => {
                //未既読状態を更新する
                updateExamData().then((examValue: any) => {
                    if (examValue !== (null || undefined)) {
                        changeActiveQuestion(page, examValue);
                        //既読処理
                        questionReadProcess(page, examValue);
                    }
                });
            });
        } else {
            //未既読状態を更新する
            updateExamData()?.then((examValue: any) => {
                if (examValue !== (null || undefined)) {
                    changeActiveQuestion(page, examValue);
                    //既読処理
                    questionReadProcess(page, examValue);
                }
            });
        }
    };

    //終了処理の各種ハンドラー

    //終了画面の「試験を終了する」ボタンクリック時の処理
    const handleClickOnEndInEndScreen = () => {
        openConfirmModal(() => {
            setIsLoading(true);

            //試験残り時間が無くなったか判定、その場合の処理
            if (isFinishedProcess()) return;

            endProcess();
        });
    };

    //終了画面無しの時の最終問題画面の終了ボタン
    const handleClickOnEndInQuestion = (data: PostQuestionArgs) => {
        setIsLoading(true);

        //試験残り時間が無くなったか判定、その場合の処理
        if (isFinishedProcess()) {
            //endProcess();
            return;
        }

        //セクションの時,選択肢が未選択,解答テキストが未入力の時,選択肢分離の時は保存しない
        if (
            !activeState.question.sectionFlag &&
            (data.choices.length !== 0 || data.answerText !== "") &&
            !state.examStateValue.choiceDeviceFlag
        )
            autoSaveAnswer(data, true);
        else {
            endProcess();
        }
        // モバイル分離テストの場合、試験終了時間の更新の処理を追加
        /*if (state.examStateValue.choiceDeviceFlag) {
            putEndStatus();
        }*/
    };

    //終了画面ありの時の最終問題画面の終了ボタン
    const handleClickOnEndForEndView = (data: PostQuestionArgs) => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "最終問題の「解答終了確認へ」ボタン")];

        //試験残り時間が無くなったか判定、その場合の処理
        if (isFinishedProcess()) return;

        //セクションの時,選択肢が未選択,解答テキストが未入力の時,選択肢分離の時は保存しない
        if (
            !activeState.question.sectionFlag &&
            (data.choices.length !== 0 || data.answerText !== "") &&
            !state.examStateValue.choiceDeviceFlag
        )
            autoSaveAnswer(data, false);

        changeActiveQuestion(
            state.examStateValue.pageMax - 1,
            state.examStateValue,
            "end-screen"
        ).then(() => {
            if (state.examStateValue.choiceDeviceFlag) {
                // 同期送信処理
                sendSyncCurrentState();
                inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + (state.examStateValue.pageMax - 1)));
            }
        }).then(() => {
            if (state.examStateValue.choiceDeviceFlag) postInLogs(inLogDatas);
        });
    };

    //終了画面あるとき、サイドバーの「試験終了確認へ」ボタンクリック時の処理
    const handleClickOnEndInSideBarForEndView = () => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "サイドバーの「解答終了確認へ」ボタン")];

        const answerTextData =
            state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? "";
        const data: PostQuestionArgs = {
            id: activeState.question.id,
            choices: state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices.map((id) => ({ choiceId: id })) ?? [],
            answerText: answerTextData,
        };

        //試験残り時間が無くなったか判定、その場合の処理
        if (isFinishedProcess()) return;

        //セクションの時,選択肢が未選択,解答テキストが未入力の時,選択肢分離の時は保存しない
        if (
            !activeState.question.sectionFlag &&
            (data.choices.length !== 0 || data.answerText !== "") &&
            !state.examStateValue.choiceDeviceFlag
        ) {
            autoSaveAnswer(data, false);
        }

        changeActiveQuestion(
            state.examStateValue.pageMax - 1,
            state.examStateValue,
            "end-screen"
        ).then(() => {
            if (state.examStateValue.choiceDeviceFlag) {
                // 同期送信処理
                sendSyncCurrentState();
                inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + (state.examStateValue.pageMax - 1)));
            }
        }).then(() => {
            if (state.examStateValue.choiceDeviceFlag) postInLogs(inLogDatas);
        });
    };

    //終了画面無いとき、サイドバーの解答終了ボタンクリック時の処理
    const clickOnEndInSideBarNoEndView = () => {
        setIsLoading(true);

        //試験残り時間が無くなったか判定、その場合の処理
        if (isFinishedProcess()) {
            //endProcess();
            return;
        }

        const answerTextData =
            state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? "";
        const data: PostQuestionArgs = {
            id: activeState.question.id,
            choices: state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices.map((id) => ({ choiceId: id })) ?? [],
            answerText: answerTextData,
        };

        //セクションの時,選択肢が未選択,解答テキストが未入力の時,選択肢分離の時は保存しない
        if (
            !activeState.question.sectionFlag &&
            (data.choices.length !== 0 || data.answerText !== "") &&
            !state.examStateValue.choiceDeviceFlag
        ) {
            autoSaveAnswer(data, true);
        } else {
            endProcess();
        }
    };

    //受験後、試験確認時の終了ボタン
    const handleClickOnEndAfterTested = () => {
        go("/");
        // 全画面表示解除
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    };

    //選択肢を分離している時の終了ボタンクリック時の処理 ※使っていない
    const handleEndByViewDevice = () => {
        go("/");
    };

    //試験の状態によって実行する終了処理を決定し、実行する
    const handleClickOnEndBySideBar = () => {
        if (isTested) {
            handleClickOnEndAfterTested();
        } else {
            state.examStateValue.endViewFlag
                ? handleClickOnEndInSideBarForEndView()
                : openConfirmModal(clickOnEndInSideBarNoEndView);
        }
    };

    // 一時離席ボタンクリック
    const handleToggleLeaveSeatButtonClick = () => {
        openConfirmModal(
            async () => {
                // 内部ログ登録用の配列
                let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "「途中退席」ボタン")];

                //試験残り時間が無くなったら退席は出来ない
                if (isFinishedProcess()) {
                    // 同期送信処理
                    //sendSyncCurrentState();
                    return;
                }

                // 退席の時はいったん回答を保存する
                if (!isLeave && !activeState.question.sectionFlag && !state.examStateValue.choiceDeviceFlag) {
                    try {
                        // 表示中の問題情報
                        const q = state.questionsArray.find(q => q.id === activeState.question.id);
                        const ro = state.examStateValue.questions.find(x => x.id === activeState.question.id)?.readonlyFlag ?? true

                        // すでに読み取り専用の場合は保存しない
                        if (q && !ro) {
                            // 既読状態情報を生成
                            const answerTextData = q.answerText ?? "";
                            const data: PostQuestionArgs = {
                                id: activeState.question.id,
                                choices: q.selectedChoices.map((id) => ({
                                    choiceId: id,
                                })),
                                answerText: answerTextData,
                            };

                            if (
                                data.choices.length !== 0 ||
                                data.answerText !== ""
                            ) {
                                await autoSaveAnswer(data, false);
                            }
                        }
                    } catch (err) {}
                }

                // 退席状態を送信
                const newIsLeave = !isLeave;

                if (state.examStateValue.choiceDeviceFlag) {
                    // 選択肢分離の場合は処理をモバイルで行う
                    setIsLeave(newIsLeave);
                    // 同期送信処理
                    sendSyncCurrentState();
                    await inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newIsLeave: " + newIsLeave));
                    postInLogs(inLogDatas);
                    return;
                }

                postCheat(
                    newIsLeave ? { startLeave: true } : { endLeave: true }
                );
                const res = await postLeave(newIsLeave);
                if (res.errorCode === 20000) {
                    setIsLeave(newIsLeave);
                    // ページ更新
                    const examData = await updateExamData();
                    if (examData) {
                        await changeActiveQuestion(
                            activeState.question.page,
                            examData as Exam
                        );
                    }
                }
            },
            isLeave ? "return_leave" : "leave"
        );
    };

    // 再同期処理
    const handleResync = () => {
        // 現在のページをモバイルに同期
        sendSyncCurrentState();
    };

    // -----汎用関数定義-----
    const isUpdatedEndFlag = () => {
        getLearner()
            .then((res: APIResponse<learner>) => {
                if (res.errorCode !== 20000) {
                    common.alertError(res.errorTitle, res.errorDetail);
                    return;
                }

                if (res.value !== null) {
                    //受験済みかどうか
                    if (res.value.executionEndDatetime !== null) {
                        go("/");
                    }
                }
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });
    };

    //試験終了処理
    const endProcess = async () => {
        // 終了処理の重複を回避
        if (isStartedEndProcess) return;

        setIsStartedEndProcess(true);
        setIsLoading(true);

        if (isTested) {
            go("/");
        } else if (state.examStateValue.choiceDeviceFlag) {
            await sendSyncEndProcess();
        } else {
            await putEndStatus(); 
        }
        // 全画面表示解除
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    };

    //試験終了状態を更新する(選択肢分離しない試験でのみ実行)
    const putEndStatus = () => {

        common.getUser()
            .then((u) => { return u?.userName ? u.userName : "null"; })
            .then((userName: string) => {
                putExam({ end: true, encUserName: common.encodeBase64(userName.toString()) })
                    .then((res: any) => {
                        if (res.errorCode !== 20000 && res.errorCode !== 40105) { // 40105: 終了処理が重複した場合も先に進む
                            common.alertError(res.errorTitle, res.errorDetail);
                            return;
                        }
                        console.log("finish");
                        isUpdatedEndFlag();

                        /*if (res.value !== null) {
                            console.log(res.value);
                            const resExam = res.value;
                            setState({
                                ...state,
                                examStateValue: resExam,
                                questionsArray: convertExamQuestions(resExam.questions),
                            });
                        }
        
                        if (res.value.choiceDeviceFlag) {
                            // 同期送信処理
                            sendSyncCurrentState();
                        }*/
                    })
                    .catch((err: any) => {
                        alert(common.ResponseMessages.Error_PutExam);
                    });
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });
    };

    //page番号からexamQuestionを特定して返す
    const getQuestionByPage = (page: number, examStateValue: Exam) => {
        let retValQuestion: ExamQuestions = initialExamQuestion;
        for (const question of examStateValue.questions) {
            if (question.page === page) {
                retValQuestion = question;
            }
        }
        return retValQuestion;
    };

    //アクティブな問題を更新
    const changeActiveQuestion = async (
        pageNum: number,
        examStateValue: Exam,
        contents?: "Questions" | "end-screen" | ""
    ) => {
        if (!appContext.fakeapi_mode) {
        // 画面接続数チェック
            const sCount = await common.checkScreenCount().catch((err: any) => {
                // ウィンドウ管理が許可されていないとエラーになるのでキャッチする
                if (err.name === "NotAllowedError") {
                    common.alertError(
                        "ブラウザの「ウィンドウ管理」を「許可する」に設定してください。",
                        "ウィンドウ管理を許可するには、アドレスバーに表示されているURLの先頭アイコンをクリックし、「ウィンドウ管理」の設定を「許可する」または「確認」に設定してください。"
                    );
                }
                return 1;
            });
        if (sCount > 1) {
            postScreenCheat({ screenCount: sCount })
                .then((data: APIResponse<{ cheatFlag: boolean }>) => {
                    if (data.errorCode !== 20000) {
                        common.alertError(data.errorTitle, data.errorDetail);
                        return;
                    }
                    if (data.value !== null) {
                        if (data.value.cheatFlag) {
                            // 画面接続数が2台以上かつ不正が有効の場合は警告メッセージを表示
                                openScreenErrorModal(() => { });
                        }
                    }
                })
                .catch((err: any) => {
                    alert(common.ResponseMessages.Error_PostScreenCheat);
                });
            }
        }

        //終了画面に遷移した場合なにもしない
        if (
            pageNum === examStateValue.pageMax - 1 &&
            examStateValue.endViewFlag
        ) {
            setActiveState((s) => {
                return {
                    ...s,
                    itemKey: pageNum,
                    contents: contents ?? "end-screen",
                };
            });
            return;
        }

        //AquestionIdの問題情報をAPIで取得してactiveQuestionに設定する
        return getQuestion({ page: pageNum })
            .then((data: APIResponse<Question>) => {
                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle, data.errorDetail);
                    return;
                }

                if (data.value !== null) {
                    //表示する問題がセクション開始画面以外で、未読の場合に既読処理をする
                    // const question = getQuestionByPage(pageNum, examStateValue);
                    // if (!question.sectionFlag && question.answerStatus === 0 && !isTested) {
                    //   setReadState({
                    //     id: question.id,
                    //     choices: []
                    //   });
                    // }

                    setActiveState((s) => {
                        return {
                            ...s,
                            itemKey: pageNum,
                            question: data.value,
                            contents: contents ?? "Questions",
                        };
                    });
                    if (
                        (data.value.choices !== null &&
                            !examStateValue.choiceDeviceFlag) ||
                        (data.value.choices !== null && isTested)
                    ) {
                        setInitialChoices(
                            data.value.choices,
                            data.value.id,
                            examStateValue
                        );
                    }
                    if (
                        data.value.answerText !== null &&
                        data.value.answerText !== "" &&
                        (!examStateValue.choiceDeviceFlag || isTested)
                    )
                        setInitialAnswerText(
                            data.value.answerText,
                            data.value.id,
                            examStateValue
                        );
                }
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetQuestion);
            });
    };

    //試験終了後問題閲覧中にアクティブな問題を更新する(既読処理、画面接続確認を行わない)
    function changeActiveQuestion_afterRead(
        pageNum: number,
        examStateValue: Exam,
        contents?: "Questions" | "end-screen"
    ) {
        //終了画面に遷移した場合なにもしない
        if (
            pageNum === examStateValue.pageMax - 1 &&
            examStateValue.endViewFlag
        ) {
            setActiveState((s) => {
                return {
                    ...s,
                    itemKey: pageNum,
                    contents: "end-screen",
                };
            });
            return;
        }

        //AquestionIdの問題情報をAPIで取得してactiveQuestionに設定する
        getQuestion({ page: pageNum })
            .then((data: APIResponse<Question>) => {
                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle, data.errorDetail);
                    return;
                }

                if (data.value !== null) {
                    setActiveState((s) => {
                        return {
                            ...s,
                            itemKey: pageNum,
                            question: data.value,
                            contents: contents ?? "Questions",
                        };
                    });
                    if (data.value.choices !== null)
                        setInitialChoices(
                            data.value.choices,
                            data.value.id,
                            examStateValue
                        );
                    if (
                        data.value.answerText !== null &&
                        data.value.answerText !== ""
                    )
                        setInitialAnswerText(
                            data.value.answerText,
                            data.value.id,
                            examStateValue
                        );
                }
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetQuestion);
            });
    }

    //過去に解答済みの選択肢情報を取り出して記録
    const setInitialChoices = (
        choices: Choice[],
        questionId: number,
        examStateValue: Exam
    ) => {
        let newQuestionsArray = convertExamQuestions(examStateValue.questions);

        for (const choice of choices) {
            if (choice.selected) {
                if (
                    !newQuestionsArray.find(q => q.id === questionId)?.selectedChoices.includes(choice.id)
                ) {
                    newQuestionsArray.find(q => q.id === questionId)?.selectedChoices.push(choice.id);
                }
            }
        }

        setState({
            examStateValue: examStateValue,
            questionsArray: newQuestionsArray,
        });
    };

    // 過去に解答済みの入力情報を取り出して記録
    const setInitialAnswerText = (
        initialText: string,
        questionId: number,
        examStateValue: Exam
    ) => {
        let newQuestionsArray = convertExamQuestions(examStateValue.questions);
        let newQuestion = newQuestionsArray.find(q => q.id === questionId);

        if (newQuestion) {
            newQuestion.answerText = initialText;
        }
        
        setState({
            examStateValue: examStateValue,
            questionsArray: newQuestionsArray,
        });
    };

    //試験残り時間が無くなったか検知、処理を実行
    const isFinishedProcess = () => {
        //試験時間過ぎていたら終了処理
        if (isFinishedTest) {
            openTimeOutModal(() => { endProcess() });
            return true;
        }
        return false;
    };

    //自動保存関数
    const autoSaveAnswer = (data: PostQuestionArgs, endFlag: boolean) => {
        //state.examStateValue.choiceDeviceFlagで選択肢分離か判断し、分離なら自動保存しない。
        //isTested試験終了後か判断し、終了後なら保存しない
        //前回の解答と変化がない場合保存しない
        if (!isTested && !state.examStateValue.choiceDeviceFlag) {
            return getQuestion({ id: data.id })
                .then((question: APIResponse<Question>) => {
                    if (question.errorCode !== 20000) {
                        common.alertError(
                            question.errorTitle,
                            question.errorDetail
                        );
                        return;
                    }

                    const newChoices = data.choices.map(
                        (choice) => choice.choiceId
                    );
                    const beforeChoices = question.value.choices
                        .filter((choice) => choice.selected)
                        .map((choice) => choice.id);
                    const sameNum =
                        [...newChoices, ...beforeChoices].filter(
                            (choice) =>
                                newChoices.includes(choice) &&
                                beforeChoices.includes(choice)
                        ).length / 2;
                    const newText = data.answerText ?? "";
                    const beforeText = question.value.answerText ?? "";

                    if (
                        !(
                            newChoices.length === beforeChoices.length &&
                            sameNum === newChoices.length
                        ) ||
                        !(newText == beforeText)
                    ) {
                        return postAnswer(data, endFlag);
                    } else if (endFlag) {
                        endProcess();
                    }
                })
                .catch((err: any) => {
                    alert(common.ResponseMessages.Error_GetQuestion_Answer);
                });
        } else if (endFlag) {
            endProcess();
        } else if (state.examStateValue.choiceDeviceFlag) {
            return Promise.resolve();
        }
    };

    //解答データを保存
    const postAnswer = (data: PostQuestionArgs, endFlag: boolean) => {
        return postQuestion(data)
            .then((data: APIResponse<null>) => {
                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle, data.errorDetail);
                    return;
                }

                if (endFlag) endProcess();
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_PostQuestion_Answer);
            });
    };

    //ExamQuestionsのデータをExpansionExamQuestionsに変換
    const convertExamQuestions = (data: ExamQuestions[]) => {
        if (data === null) return [];

        const resultData: ExpansionExamQuestions[] = data.map((value) => ({
            id: value.id,
            page: value.page,
            sectionFlag: value.sectionFlag,
            parentQuestionId: value.parentQuestionId,
            subjectText: value.subjectText,
            answerStatus: value.answerStatus,
            selectedChoices: [],
            answerText: "",
            readonlyFlag: value.readonlyFlag as boolean,
        }));
        return resultData;
    };

    //サイドバーの問題番号振り分け
    const setQuestionHeadnum = (questionArg: ExpansionExamQuestions) => {
        let sectionNum = 0;
        let commonNum = 0;
        let headNum = "";
        let sectionHeadNum = "";

        for (const question of state.questionsArray) {
            if (question.sectionFlag) {
                sectionNum = sectionNum + 1;

                if (questionArg.id === question.id) {
                    return (sectionHeadNum = sectionNum.toString());
                }
            } else if (question.parentQuestionId === 0) {
                commonNum = commonNum + 1;

                if (questionArg.id === question.id) {
                    return (headNum = "問" + commonNum);
                }
            } else {
                commonNum = commonNum + 1;

                if (questionArg.id === question.id) {
                    return (headNum = "問" + commonNum);
                }
            }
        }

        return headNum;
    };

    //開始画面のないセクション問題をquestionsから削除
    const removeNotStartViewSection = (questions: ExpansionExamQuestions[]) => {
        let questionArgs = questions.concat();
        let pages: number[] = [];
        for (const question of questionArgs) {
            if (pages.includes(question.page)) {
                const index = pages.indexOf(question.page);
                if (questionArgs[index].sectionFlag) {
                    questionArgs.splice(index, 1);
                }
            }

            pages.push(question.page);
        }
        return questionArgs;
    };

    //サイドリストを表示するかどうかの判定
    const isHiddenSideList = () => {
        let flag = false;

        if (state.examStateValue.startViewFlag && activeState.itemKey === 0)
            flag = true;
        if (
            state.examStateValue.endViewFlag &&
            activeState.itemKey === state.examStateValue.pageMax - 1
        )
            flag = true;
        return flag;
    };

    //残り時間表示関数
    const dispRemainingTime = () => {
        const time = timeState.remainingTime;

        const allMinutes = Math.floor(time / 60);
        const hours = Math.floor(allMinutes / 60);
        const minutes = allMinutes - hours * 60;
        const seconds = time - minutes * 60 - hours * 60 * 60;
        return hours + ":" + paddZero(minutes) + ":" + paddZero(seconds);
    };

    const paddZero = (argNum: number) => {
        let retVal = String(argNum);
        if (argNum < 10) retVal = "0" + retVal;
        return retVal;
    };

    const sideBarEndButtonText = () => {
        let text = "";
        //if (state.examStateValue.choiceDeviceFlag || isTested) {
        if (isTested) {
            text = "問題の表示を終了する";
        } else if (state.examStateValue.endViewFlag) {
            text = "解答終了確認へ";
        } else {
            //text = "解答を終了する"
            text = "試験を終了する";
        }
        return text;
    };

    //モバイルで試験終了していた時の処理(モバイル分離時)
    const returnHomeIfNeeded = () => {
        return isTestedByMobile()
            .then((isTested: boolean | void | undefined) => {
                if (isTested) {
                    openFinishedModal(() => go("/"));
                    return true;
                }
                return false;
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });
    };

    //モバイルで試験終了したか確認(モバイル分離時)
    function isTestedByMobile() {
        const retVal = getLearner()
            .then((res: APIResponse<learner>) => {
                if (res.errorCode !== 20000) {
                    common.alertError(res.errorTitle, res.errorDetail);
                    return;
                }

                let isTested = false;

                if (res.value.executionEndDatetime !== null) {
                    isTested = true;
                }

                return isTested;
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });
        return retVal;
    }

    // ブラウザのフォーカス不正登録
    const detectFocus = (isFocused: boolean) => {
        if (isFocused) {
            postCheat({ PCFocus: true });
        } else {
            postCheat({ PCBlur: true });
        }
    };

    // 内部ログ用データ作成
    const makeInLogsData = (res: any, code: InLogsCode, descripton?: string) => {

        var result: IInLogs = {
            logdatetime: new Date(),
            category1: "PCSPSync",
            category3: code,
            type: res.errorCode === 20000 ? 0 : 1,
            description: descripton ? descripton + " by PC" : "by PC"
        }

        return result;
    }

    //--------modal関係----------

    //QuestionContentsコンポーネントに渡す、試験終了処理関数
    //終了画面なしの場合
    const endBtnFunc_NotExistEndView = (data: PostQuestionArgs) => {
        openConfirmModal(() => handleClickOnEndInQuestion(data));
    };
    //終了画面ありの場合
    const endBtnFunc_ExistEndView = (data: PostQuestionArgs) => {
        handleClickOnEndForEndView(data);
    };

    //処理の確認モーダルを表示(実行する関数を渡す)
    const openConfirmModal = (
        executionFunc: any,
        modalContent: "leave" | "return_leave" | "end" = "end"
    ) => {
        setModalFunction(() => executionFunc);
        //モーダルの中身
        let _modalBody: JSX.Element;
        switch (modalContent) {
            case "leave":
                _modalBody = (
                    <div className={classNames.modalText}>
                        <p id="simple-modal-title">
                            途中退席してもよろしいですか？
                        </p>
                        <p id="warning">
                            途中退席すると、既に閲覧した問題については、
                            未回答であっても解答することはできなくなります。
                        </p>
                    </div>
                );
                break;
            case "return_leave":
                _modalBody = (
                    <div className={classNames.modalText}>
                        <p id="simple-modal-title">
                            試験を再開してもよろしいですか？
                        </p>
                    </div>
                );
                break;
            case "end":
                _modalBody = (
                    <div className={classNames.modalText}>
                        <h2 id="simple-modal-title">
                            解答を終了してもよろしいですか？
                        </h2>
                        <p id="warning">
                            ※一度解答を終了すると再解答は出来ません
                        </p>
                    </div>
                );
                break;
        }
        setModalBody(_modalBody);
        setOpenModal(true);
    };

    const openFinishedModal = (executionFunc: any) => {
        setModalFunction(() => executionFunc);
        setFinishedModalBody(
            <div className={classNames.finishedModalText}>
                <p className="modaltext">解答を終了しました</p>
                <p className="modaltext">問題の表示を終了します</p>
            </div>
        );
        setIsFinishedByMobile(true);
    };

    const openTimeOutModal = (executionFunc: any) => {
        setModalFunction(() => executionFunc);
        setFinishedModalBody(
            <div className={classNames.finishedModalText}>
                <p className="modaltext">残り時間が無くなりました</p>
                <p className="modaltext">試験を終了します</p>
            </div>
        );
        setIsFinishedByMobile(true);
    };

    const openScreenErrorModal = (executionFunc: any) => {
        setModalFunction(() => executionFunc);
        setScreenErrorModalBody(
            <div className={classNames.modalText}>
                <p id="warning">
                    接続されているディスプレイが2台以上あることを検出しました。速やかに、2台目以降のディスプレイを外してください。
                </p>
            </div>
        );
        setIsScreenError(true);
    };

    //対象のセクション内の子問題をすべて返す
    const getChildrenQuestion = (
        parentId: number,
        questionsArray: ExpansionExamQuestions[]
    ) => {
        let children = questionsArray.filter(
            (q) => q.parentQuestionId === parentId
        );
        return children;
    };

    //-----未既読機能関係の関数-------

    //問題の既読処理
    const questionReadProcess = async (
        pageNum: number,
        examStateValue: Exam
    ) => {
        const question = getQuestionByPage(pageNum, examStateValue);
        if (!question.sectionFlag && question.answerStatus === 0 && !isTested) {
            setReadState({
                id: question.id,
                choices: [],
                answerText: "",
            });
        }
    };

    //問題を既読にする
    const setReadState = (data: PostQuestionArgs) => {
        postQuestion(data)
            .then((data: APIResponse<null>) => {
                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle, data.errorDetail);
                    return;
                }
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_PostQuestion_Answer);
            });
    };

    //サイドバーの更新
    const updateExamData = async () => {
        if (appContext.fakeapi_mode) {
            return state.examStateValue;
        }

        try {
            const data = await getExam();
            if (data.errorCode !== 20000) {
                common.alertError(data.errorTitle, data.errorDetail);
                return;
            }
            if (data.value !== null) {
                const resExam = data.value;
                setState({
                    ...state,
                    examStateValue: resExam,
                    questionsArray: convertExamQuestions(resExam.questions),
                });
                return resExam;
            }
        } catch (err) {
            alert(common.ResponseMessages.Error_GetExam);
        }
    };

    const isAnsweredAllChildren = (parentId: number) => {
        const children = getChildrenQuestion(parentId, state.questionsArray);
        let isAnsweredAll = true;

        for (const child of children) {
            if (child.answerStatus !== 2 && !child.readonlyFlag)
                isAnsweredAll = false;
        }

        return isAnsweredAll;
    };

    const isReadonlyAllChildren = (parentId: number) => {
        const children = getChildrenQuestion(parentId, state.questionsArray);
        let isReadonlyAll = true;

        for (const child of children) {
            if (!child.readonlyFlag) isReadonlyAll = false;
        }

        return isReadonlyAll;
    };

    const swichStyleByAnswerState = (question: ExpansionExamQuestions) => {
        let style = "";

        if (question.readonlyFlag) {
            style = classNames.readOnlyPage;
            return style;
        }

        if (question.sectionFlag) {
            const isReadonlyAll = isReadonlyAllChildren(question.id);
            const isAnswerdAll = isAnsweredAllChildren(question.id);
            isReadonlyAll
                ? (style = classNames.readOnlyPage)
                : isAnswerdAll
                ? (style = classNames.answeredPage)
                : (style = classNames.notActivePage);
            return style;
        }

        switch (question.answerStatus) {
            case 0:
                style = classNames.notActivePage;
                break;

            case 1:
                style = classNames.readPage;
                break;

            case 2:
                style = classNames.answeredPage;
                break;
        }
        return style;
    };

    const searchExtensions = (extensionCode: ExtCode, exam: Exam) => {
        for (const extension of exam.extensions) {
            if (extension.code == extensionCode && extension.parameter == 1)
                return true;
        }
        return false;
    };

    //-----スタイルの宣言-------
    const classNames = useStyles();

    // -----state-----
    const [state, setState] = React.useState({
        examStateValue: initialExam,
        questionsArray: initialExamQuestions,
    });
    /**
     * 表示中の問題を表すステート
     */
    const [activeState, setActiveState] = React.useState<IActiveState>({
        question: initialQuestion,
        contents: "",
        itemKey: 0,
    });

    const [examExtensions, setExamExtensions] = React.useState<IExamExtensions>(
        {
            leave: false,
            record: false,
            self_photo: false,
            env_record: false,
            prebrief: false,
        }
    );

    //試験を受験済みかどうか
    const [isTested, setIsTested] = React.useState<boolean>(false);
    // 退席中かどうか
    const [isLeave, setIsLeave] = React.useState<boolean>(false);
    //試験受験中、試験時間が終了したかどうか
    const [isFinishedTest, setIsFinishedTest] = React.useState<boolean>(false);
    //試験受験中、試験終了処理を開始したかどうか
    const [isStartedEndProcess, setIsStartedEndProcess] = React.useState<boolean>(false);
    //アコーディオンの拡張フラグ
    const [isExpanded, setIsExpanded] = React.useState<boolean>(true);
    //API通信待ち中のローディング画面表示フラグ
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    //初期表示、リロード時に設定する同期送信フラグ
    const [syncFlag, setSyncFlag] = React.useState<boolean>(false);

    //モーダル関係
    const [openModal, setOpenModal] = React.useState(false);
    const [modalBody, setModalBody] = React.useState(<div />);
    const [isFinishedByMobile, setIsFinishedByMobile] = React.useState(false);
    const [modalFunction, setModalFunction] = React.useState<any>();
    const [finishedModalBody, setFinishedModalBody] =
        React.useState<JSX.Element>(<div />);
    const [isScreenError, setIsScreenError] = React.useState(false);
    const [screenErrorModalBody, setScreenErrorModalBody] = React.useState(
        <div />
    );

    // useSync処理中
    const [isProcessingUseSync, setIsProcessingUseSync] = React.useState<boolean>(false);

    /***
     * 残り時間を表すステート
     */
    const [timeState, setTimeState] = React.useState<ITimeState>({
        timeUpdateTime: 0,
        remainingTimeMS: 0,
        remainingTime: 0,
    });

    // 描画を通じて同じものを指すコールバック用の参照を作成
    const updateLeaveState = React.useRef(() => { });

    // コールバック内で使用しているstateが変わっていたらコールバックを更新
    // => setintervalのコールバック内では自動でstateの変更が反映されないため
    // コールバック自体を更新する
    updateLeaveState.current = React.useCallback(() => {
        try {
            if (
                !isFinishedTest &&
                !isTested &&
                state.examStateValue?.choiceDeviceFlag && !appContext.fakeapi_mode
            ) {
                getLearner(undefined, true).then((res) => {
                    if (res && res.errorCode === 20000 && res.value) {
                        setIsLeave(res.value.leaveFlag);
                    }
                });
            }
        } catch (err) {}
    }, [isFinishedTest, isTested, state, getLearner]);

    // -----use effefct-----
    React.useEffect(() => {
        // コンポーネントが破棄されているかどうか
        // objectなのは、コールバックの中で参照するため
        const oMounted = { mounted: true };

        // 退席状態ポーリング
        const pollingId = setInterval(() => {
            if (!oMounted.mounted) {
                clearInterval(pollingId);
                return;
            }
            updateLeaveState.current.call(null);
        }, POLLING_INTERVAL_MS);

        // ブラウザのフォーカス関連のイベント
        window.addEventListener("blur", () => detectFocus(false));
        window.addEventListener("focus", () => detectFocus(true));

        let endSeconds = 0;
        let resExam: Exam;
        const dtNow = new Date();
        getExam()
            .then((data: APIResponse<Exam>) => {
                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle, data.errorDetail);
                    return;
                }
                if (data.value !== null) {
                    resExam = data.value;
                    endSeconds = data.value.endSeconds;
                    setState({
                        ...state,
                        examStateValue: resExam,
                        questionsArray: convertExamQuestions(resExam.questions),
                    });

                    //各種拡張機能の有無をフラグにセット
                    setExamExtensions({
                        leave: searchExtensions(ExtCode.leave, resExam),
                        record: searchExtensions(ExtCode.record, resExam),
                        self_photo: searchExtensions(
                            ExtCode.self_photo,
                            resExam
                        ),
                        env_record: searchExtensions(
                            ExtCode.env_record,
                            resExam
                        ),
                        prebrief: searchExtensions(ExtCode.prebrief, resExam),
                    });

                    //let isViewExam = true
                    // //試験終了後の問題閲覧が不可のテストで、試験終了後にアクセスされた場合、問題表示処理をスキップ
                    // if (resExam.startStatus === 2 && !resExam.afterReadFlag) {
                    //   isViewExam = false;
                    // }

                    // if (isViewExam) {
                    //   //開始画面をスキップする
                    //   if (resExam.startViewFlag) {
                    //     setActiveState(s => {
                    //       return { ...s, itemKey: 1 };
                    //     });
                    //     changeActiveQuestion(1, resExam, "Questions");
                    //   }
                    //   else {
                    //     changeActiveQuestion(0, resExam, "Questions");
                    //   }

                    // }
                }

                getLearner()
                    .then(async (res: APIResponse<learner>) => {
                        if (res.errorCode !== 20000) {
                            common.alertError(res.errorTitle, res.errorDetail);
                            return;
                        }

                        if (res.value !== null) {
                            let isViewExam = true;
                            //試験終了後の問題閲覧が不可のテストで、試験終了後にアクセスされた場合、問題表示処理をスキップ
                            if (
                                (res.value.executionEndDatetime !== null ||
                                    resExam.startStatus === 2) &&
                                !resExam.afterReadFlag
                            ) {
                                isViewExam = false;
                            }

                            if (isViewExam) {
                                //受験済みで開始画面ありならスキップ
                                if (
                                    res.value.executionEndDatetime !== null ||
                                    resExam.startStatus === 2
                                ) {
                                    if (resExam.startViewFlag) {
                                        changeActiveQuestion_afterRead(
                                            1,
                                            resExam,
                                            "Questions"
                                        );
                                    } else {
                                        changeActiveQuestion_afterRead(
                                            0,
                                            resExam,
                                            "Questions"
                                        );
                                    }
                                    setIsTested(true);
                                }

                                //試験期間中、もしくは未受験かどうか
                                if (
                                    (resExam.termType === 0 &&
                                        resExam.startStatus !== 2) ||
                                    (resExam.termType === 1 &&
                                        res.value.executionEndDatetime === null)
                                ) {
                                    if (resExam.startViewFlag) {
                                        await changeActiveQuestion(
                                            1,
                                            resExam,
                                            "Questions"
                                        );
                                    } else {
                                        await changeActiveQuestion(
                                            0,
                                            resExam,
                                            "Questions"
                                        );
                                    }

                                    // 受験時のIP、UAが未登録の場合DBを更新
                                    /*if (
                                        res.value.executionPCIP === null || res.value.executionPCIP === ""
                                    ) {
                                        putExam({ start: true, PCStartFlag: true })
                                            .then((res: any) => {
                                                if (res.errorCode !== 20000) {
                                                    common.alertError(res.errorTitle, res.errorDetail);
                                                    return;
                                                }
                                            })
                                            .catch((err: any) => {
                                                alert(common.ResponseMessages.Error_PutExam);
                                            });
                                    }*/

                                    //画面遷移後すぐにカウントダウン開始
                                    setTimeState({
                                        timeUpdateTime: dtNow.getTime(),
                                        remainingTimeMS: endSeconds * 1000,
                                        remainingTime: endSeconds,
                                    });

                                    //1問目を既読にする(1問目が未読の時だけ既読にする)
                                    if (
                                        !resExam.questions[0].readonlyFlag &&
                                        resExam.questions[0].answerStatus == 0
                                    ) {
                                        setReadState({
                                            id: resExam.questions[0].id,
                                            choices: [],
                                            answerText: "",
                                        });
                                    }
                                }
                            }

                            // 退席中フラグ
                            setIsLeave(res.value.leaveFlag);

                            // 選択肢分離かつ試験期間中のとき初期表示、リロード時の同期送信フラグ設定
                            if (
                                resExam.choiceDeviceFlag &&
                                resExam.startStatus === 1
                            ) {
                                setSyncFlag(true);
                            }
                        }

                        setIsLoading(false);
                    })
                    .catch((err: any) => {
                        alert(common.ResponseMessages.Error_GetLearner);
                    });
            })
            .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetExam);
            });

        return () => {
            oMounted.mounted = false;

            window.removeEventListener("blur", () => detectFocus(false));
            window.removeEventListener("focus", () => detectFocus(true));
        };
    }, []);

    React.useEffect(() => {
        // 初期表示、リロード時の同期送信
        if (syncFlag) {
            sendSyncCurrentState();
        }
    }, [syncFlag]);

    /**
     * 同期処理初期化
     */
    useSync("PCHome", false, async (label, data) => {
        switch (label) {
            case "HOME":
                // {
                //     completeProcess: string
                // }
                return;
            case "MobileHomeEnvironmentMovie":
            case "MobileHomeTakePhoto":
                // {
                //     completeProcess: string
                // }
                return;
            case "MobileHome":
                // {
                //     state: _state,
                //     activeState: _activeState,
                //     isLeave: _isLeave,
                //     isTested: _isTested,
                // }
                //   or
                // {
                //     isTested: true
                // }
                break;
            case "PCHome":
                // {
                //     activeState: _activeState,
                //     timeState: _timeState,
                //     isLeave: _isLeave,
                //     isTested: _isTested
                // }
                // 念のため、自分からは無視
                return;
            case "PCStandByScreen":
                // {
                //     completeProcess: _completeProcess
                // }
                return;
            default:
                console.error("想定外の同期元：" + label);
                break;
        }

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.get_sync)];

        let alreadyStart = false;
        setIsProcessingUseSync((_isProcessingUseSync) => {
            alreadyStart = _isProcessingUseSync;
            return true;
        });

        if (alreadyStart) return;

        // 同期処理受信処理
        //試験終了処理がモバイル側で実行された場合、TOP画面へ遷移する
        if (data.isTested) {
            go("/");
            // 全画面表示解除
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
            return;
        }

        setIsLeave(data.isLeave);
        setIsTested(data.isTested);

        setState({
            examStateValue: data.state.examStateValue,
            questionsArray: data.state.questionsArray,
        });
        await changeActiveQuestion(
            data.activeState.itemKey,
            data.state.examStateValue,
            data.activeState.contents
        );

        await inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.display_changed));
        postInLogs(inLogDatas);

        setIsProcessingUseSync(false);
    });

    /**
   * 試験終了時用の同期処理送信処理
   */
    function sendSyncEndProcess() {
      // 試験終了フラグのみ送信する
      sendSync("PCHome", {
        isTestedByPC: true
      })
    }

    /**
     * 同期処理送信処理
     */
    function sendSyncCurrentState() {
        // stateの変更は非同期なので、
        // 更新後の値で同期する
        setActiveState((_activeState) => {
            setTimeState((_timeState) => {
                setIsLeave((_isLeave) => {
                    setIsTested((_isTested) => {
                        sendSync("PCHome", {
                            activeState: _activeState,
                            timeState: _timeState,
                            isLeave: _isLeave,
                            isTested: _isTested,
                        });
                        return _isTested;
                    });
                    return _isLeave;
                });
                return _timeState;
            });
            return _activeState;
        });
    }

    /**
     * タイマー処理初期化
     */
    common.useInterval(
        () => {},
        () => {
            setTimeState((s) => {
                if (
                    !isFinite(s.remainingTime) ||
                    s.remainingTime <= 0 ||
                    !s.timeUpdateTime
                ) {
                    return s;
                }
                const dtNow = new Date();
                const diff = dtNow.getTime() - s.timeUpdateTime;
                const remainingTimeMS =
                    (s.remainingTimeMS == undefined
                        ? s.remainingTime * 1000
                        : s.remainingTimeMS) - diff;
                const remainingTime = Math.floor(remainingTimeMS / 1000);
                if (remainingTime <= 0) {
                    setIsFinishedTest(true);
                    // 試験時間終了時の処理
                    // すでに終了処理を開始している場合は何もしない
                    if (!isStartedEndProcess) {
                        if (state.examStateValue.choiceDeviceFlag) {
                            // モバイル分離テストの場合、試験終了を送信
                            endProcess();
                        } else {
                            // 試験時間終了時の解答を保存して終了処理
                            // セクション,終了画面,退席中でなければ保存する
                            if (!isLeave && !activeState.question.sectionFlag &&
                                !(state.examStateValue.endViewFlag && activeState.itemKey === state.examStateValue.pageMax - 1)
                            ) {
                                const answerTextData =
                                    state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? "";
                                const data: PostQuestionArgs = {
                                    id: activeState.question.id,
                                    choices: state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices.map((id) => ({ choiceId: id })) ?? [],
                                    answerText: answerTextData,
                                };
                                // 選択肢が未選択,解答テキストが未入力の時では保存しない
                                if (data.choices.length !== 0 || data.answerText !== "") {
                                    autoSaveAnswer(data, true);
                                } else {
                                    endProcess();
                                }
                            } else {
                                endProcess();
                            }
                        }
                    }
                }
                return {
                    timeUpdateTime: dtNow.getTime(),
                    remainingTimeMS,
                    remainingTime: remainingTime < 0 ? 0 : remainingTime,
                };
            });
        },
        500
    );

    const hiddenFlagsValue: hiddenFlags = makeHiddenFlags(
        state,
        activeState.itemKey
    );

    let qeustionContents;
    let mainContainerWidth;

    switch (activeState.contents) {
        case "end-screen":
            qeustionContents = state.examStateValue.endViewFlag ? (
                <EndScreen
                    isTested={isTested}
                    isFinishedTest={isFinishedTest}
                    exam={state.examStateValue}
                    hiddenFlags={hiddenFlagsValue}
                    handleReturnClick={handleClickOnReturn_EndScreen}
                    handleEndClickOnEndScreen={handleClickOnEndInEndScreen}
                    handleEndClickAfterTested={handleClickOnEndAfterTested}
                    handleEndByViewDevice={handleEndByViewDevice}
                />
            ) : (
                <QuestionContents
                    activeItemKey={activeState.itemKey}
                    isLoading={isLoading}
                    isExpanded={isExpanded}
                    remainingTime={timeState.remainingTime}
                    examValue={state.examStateValue}
                    isTested={isTested}
                    selectedChoices={
                        state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices ?? []
                    }
                    answerText={
                        state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? ''
                    }
                    question={activeState.question}
                    hiddenFlags={hiddenFlagsValue}
                    handleReturnClick={handleClickOnReturn}
                    handleNextClick={handleClickOnNext}
                    handleChange={handleChange}
                    handleTextChange={handleTextChange}
                    handleEndClickNoEndView={endBtnFunc_NotExistEndView}
                    handleEndClickExsistEndView={endBtnFunc_ExistEndView}
                    handleEndClickAfterTested={handleClickOnEndAfterTested}
                    handleExpandChange={handleChangeExpansion}
                    handleEndByViewDevice={handleEndByViewDevice}
                    getQuestionHeadnum={setQuestionHeadnum}
                />
            );
            mainContainerWidth = "800px";
            break;
        case "Questions":
            qeustionContents = (
                <QuestionContents
                    activeItemKey={activeState.itemKey}
                    isLoading={isLoading}
                    isExpanded={isExpanded}
                    remainingTime={timeState.remainingTime}
                    examValue={state.examStateValue}
                    isTested={isTested}
                    selectedChoices={
                        state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices ?? []
                    }
                    answerText={
                        state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? ''
                    }
                    question={activeState.question}
                    hiddenFlags={hiddenFlagsValue}
                    handleReturnClick={handleClickOnReturn}
                    handleNextClick={handleClickOnNext}
                    handleChange={handleChange}
                    handleTextChange={handleTextChange}
                    handleEndClickNoEndView={endBtnFunc_NotExistEndView}
                    handleEndClickExsistEndView={endBtnFunc_ExistEndView}
                    handleEndClickAfterTested={handleClickOnEndAfterTested}
                    handleExpandChange={handleChangeExpansion}
                    handleEndByViewDevice={handleEndByViewDevice}
                    getQuestionHeadnum={setQuestionHeadnum}
                />
            );
            mainContainerWidth = "question";
            break;
        default:
            qeustionContents = <div></div>;
            mainContainerWidth = "800px";
            break;
    }

    // 退席中は回答欄を見せない？
    if (isLeave && !isTested) {
        qeustionContents = <div>退席中です</div>;
    }

    let headerRemainingTime = (
        <span
            className={classNames.remainingTimeBox}
            hidden={isTested || state.examStateValue.startStatus === 2}
        >
            残り時間：
            <span
                className={classNames.remainingTime}
                hidden={isTested || state.examStateValue.startStatus === 2}
            >
                {dispRemainingTime()}
            </span>
        </span>
    );

    let headerSlider = (
        <PrettoSlider
            hidden={isTested || state.examStateValue.startStatus === 2}
            min={0}
            max={state.examStateValue.executeTime * 60}
            value={
                state.examStateValue.executeTime * 60 - timeState.remainingTime
            }
        ></PrettoSlider>
    );

    let fullScreen = (
        <ButtonContainer className={classNames.fullButtonContainer}>
            <DefaultButton id={"fullscreenBtn"} onClick={() => document.documentElement.requestFullscreen()}>
                <ZoomOutMapIcon style={{ fontSize: "26" }} />
            </DefaultButton>
        </ButtonContainer>
    );

    //試験終了後にアクセスされた場合に表示する画面(試験終了後の問題閲覧が不可あるいは試験終了日時から10分経過していない場合)
    const finishedExamPage = (
        <Box display="flex" flexDirection="column" justifyContent="center">
            <Box
                marginBottom={6}
                marginTop={6}
                style={{
                    fontSize: common.FONT_SIZE.mainText,
                    textAlign: "center",
                }}
            >
                この試験はすでに終了しています。
            </Box>
            <ButtonContainer className={classNames.buttonContainer}>
                <DefaultBackButton
                    className="backBtn"
                    onClick={() => {
                        go("/");
                    }}
                >
                    <ArrowBackIosIcon />
                    <span>&nbsp;トップへ戻る</span>
                </DefaultBackButton>
            </ButtonContainer>
        </Box>
    );
    const isFinishedNotAfterReadTest =
        (isTested || state.examStateValue.startStatus === 2) &&
        !state.examStateValue.afterReadFlag;

    //試験終了後引数の分数経過したかどうかの判定
    const isFinishOverMinutes = (time: number) => {

        const d1 = new Date(); // 現在時刻
        const d2 = new Date(state.examStateValue.executeEnd); // 試験終了日時
        const timeDifference = d1.getTime() - d2.getTime();
        if (timeDifference >= time * 60 * 1000) return true
        return false

    }

    const isFinishedBeforeStartRead =
        (isTested || state.examStateValue.startStatus === 2) &&
        state.examStateValue.afterReadFlag && 
        !isFinishOverMinutes(10);

    return (
        <Layout
            viewType="pc"
            width={mainContainerWidth}
            headerRemainingTime={headerRemainingTime}
            headerSlider={headerSlider}
            isTutorial={appContext.fakeapi_mode}
            allowResync={
                state.examStateValue.choiceDeviceFlag &&
                state.examStateValue.startStatus === 1
            }
            handleResync={handleResync}
            fullScreen={fullScreen}
        >
            {(isFinishedNotAfterReadTest || isFinishedBeforeStartRead) ? (
                finishedExamPage
            ) : (
                <div
                    style={{
                        display: "flex",
                        backgroundColor: "white",
                        padding: "30px 0px 50px 0px",
                    }}
                >
                    {isLoading ? (
                        <ReactLoading
                            type={"spin"}
                            color={"#003f71"}
                            height={"7rem"}
                            width={"7rem"}
                            className={classNames.loadingStyle}
                        />
                    ) : (
                        <Grid
                            container
                            justify={
                                activeState.contents === "Questions"
                                    ? "flex-start"
                                    : "center"
                            }
                            spacing={0}
                        >
                            <Grid
                                item
                                sm={1}
                                md={2}
                                lg={3}
                                className={
                                    activeState.contents === "Questions"
                                        ? classNames.sideBarListStyle
                                        : ""
                                }
                                hidden={isHiddenSideList()}
                            >
                                <div
                                    hidden={isTested}
                                    className={classNames.sideBarDescription}
                                >
                                    解答状況によって、問題番号の背景色が次の通りに変化します。
                                    <p>
                                        {" "}
                                        閲覧済み ⇒{" "}
                                        <span id="orange">オレンジ色</span>
                                    </p>
                                    <p>
                                        解答済み ⇒ <span id="blue">青色</span>
                                    </p>
                                    <p>
                                        問題選択中 ⇒{" "}
                                        <span id="green">黄緑色</span>
                                    </p>
                                    <p hidden={!examExtensions.leave}>
                                        解答不能 ⇒ <span id="gray">グレー</span>
                                    </p>
                                    {/*<p hidden={!examExtensions.leave} id="sideBar_warningText">※途中退席時、それまでに閲覧済みの問題は解答不能になります。</p>*/}
                                    {/* <p id="sideBar_warningText">※問題番号をクリックすることで表示問題を切り替えられます</p> */}
                                </div>

                                <MediaQuery query="(max-width: 1200px)">
                                    {isLeave ? (
                                        <div></div>
                                    ) : (
                                        <List
                                            style={{
                                                maxHeight: "500px",
                                                overflow: "auto",
                                                width: "203px",
                                            }}
                                        >
                                            {removeNotStartViewSection(
                                                state.questionsArray
                                            ).map((question, idx) => (
                                                <Box
                                                    key={question.id}
                                                    className={
                                                        question.page ===
                                                        activeState.itemKey
                                                            ? classNames.activePage
                                                            : isTested
                                                            ? classNames.notActivePage
                                                            : swichStyleByAnswerState(
                                                                  question
                                                              )
                                                    }
                                                >
                                                    <ListItem
                                                        key={question.id}
                                                        button
                                                        onClick={() =>
                                                            handleClickOnSideBar(
                                                                question.page
                                                            )
                                                        }
                                                    >
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            className={
                                                                classNames.sideBarTextStyle
                                                            }
                                                        >
                                                            <Grid item xs={12}>
                                                                <section
                                                                    style={
                                                                        question.sectionFlag
                                                                            ? {
                                                                                  border: "solid 1px",
                                                                                  padding:
                                                                                      "0px 7px",
                                                                                  fontWeight:
                                                                                      "bold",
                                                                                  fontSize:
                                                                                      "2rem",
                                                                                  width: "fit-content",
                                                                              }
                                                                            : {
                                                                                  fontWeight:
                                                                                      "bold",
                                                                                  fontSize:
                                                                                      "2rem",
                                                                              }
                                                                    }
                                                                >
                                                                    {setQuestionHeadnum(
                                                                        question
                                                                    )}
                                                                </section>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {question.subjectText +
                                                                    "..."}
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                    <Divider variant="fullWidth" />
                                                </Box>
                                            ))}
                                        </List>
                                    )}

                                    <div
                                        className={
                                            classNames.sideBarButtonPosition
                                        }
                                    >
                                        <DefaultButton
                                            hidden={
                                                isTested ||
                                                !examExtensions.leave
                                            }
                                            onClick={
                                                handleToggleLeaveSeatButtonClick
                                            }
                                        >
                                            {isLeave
                                                ? "試験を再開する"
                                                : "途中退席する"}
                                        </DefaultButton>
                                        <p
                                            hidden={
                                                isTested ||
                                                !examExtensions.leave
                                            }
                                            id="sideBar_warningText"
                                        >
                                            ※途中退席時、それまでに閲覧済みの問題は解答不能になります。
                                        </p>
                                        <DefaultButton
                                            onClick={
                                                /*state.examStateValue
                                                    .choiceDeviceFlag
                                                    ? handleEndByViewDevice
                                                    :*/ handleClickOnEndBySideBar
                                            }
                                            hidden={
                                                !isTested &&
                                                (isLeave/* ||
                                                    state.examStateValue
                                                        .choiceDeviceFlag*/)
                                            }
                                        >
                                            {sideBarEndButtonText()}
                                        </DefaultButton>
                                    </div>
                                </MediaQuery>

                                <MediaQuery query="(min-width: 1200px)">
                                    {isLeave ? (
                                        <div></div>
                                    ) : (
                                        <List
                                            style={{
                                                maxHeight: "500px",
                                                overflow: "auto",
                                                width: "203px",
                                            }}
                                        >
                                            {removeNotStartViewSection(
                                                state.questionsArray
                                            ).map((question, idx) => (
                                                <Box
                                                    key={question.id}
                                                    className={
                                                        question.page ===
                                                        activeState.itemKey
                                                            ? classNames.activePage
                                                            : isTested
                                                            ? classNames.notActivePage
                                                            : swichStyleByAnswerState(
                                                                  question
                                                              )
                                                    }
                                                >
                                                    <ListItem
                                                        key={question.id}
                                                        button
                                                        onClick={() =>
                                                            handleClickOnSideBar(
                                                                question.page
                                                            )
                                                        }
                                                    >
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            className={
                                                                classNames.sideBarTextStyle
                                                            }
                                                        >
                                                            <Grid item xs={12}>
                                                                <section
                                                                    style={
                                                                        question.sectionFlag
                                                                            ? {
                                                                                  border: "solid 1px",
                                                                                  padding:
                                                                                      "0px 7px",
                                                                                  fontWeight:
                                                                                      "bold",
                                                                                  fontSize:
                                                                                      "2rem",
                                                                                  width: "fit-content",
                                                                              }
                                                                            : {
                                                                                  fontWeight:
                                                                                      "bold",
                                                                                  fontSize:
                                                                                      "2rem",
                                                                              }
                                                                    }
                                                                >
                                                                    {setQuestionHeadnum(
                                                                        question
                                                                    )}
                                                                </section>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {question.subjectText +
                                                                    "..."}
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                    <Divider variant="fullWidth" />
                                                </Box>
                                            ))}
                                        </List>
                                    )}

                                    <div
                                        className={
                                            classNames.sideBarButtonPosition
                                        }
                                    >
                                        <DefaultButton
                                            hidden={
                                                isTested ||
                                                !examExtensions.leave
                                            }
                                            onClick={
                                                handleToggleLeaveSeatButtonClick
                                            }
                                        >
                                            {isLeave
                                                ? "試験を再開する"
                                                : "途中退席する"}
                                        </DefaultButton>
                                        <p
                                            hidden={
                                                isTested ||
                                                !examExtensions.leave
                                            }
                                            id="sideBar_warningText"
                                        >
                                            ※途中退席時、それまでに閲覧済みの問題は解答不能になります。
                                        </p>
                                        <DefaultButton
                                            onClick={
                                                /*state.examStateValue
                                                    .choiceDeviceFlag
                                                    ? handleEndByViewDevice
                                                    :*/ handleClickOnEndBySideBar
                                            }
                                            hidden={
                                                !isTested &&
                                                (isLeave /*||
                                                    state.examStateValue
                                                        .choiceDeviceFlag*/)
                                            }
                                        >
                                            {sideBarEndButtonText()}
                                        </DefaultButton>
                                    </div>
                                </MediaQuery>
                            </Grid>

                            <div
                                hidden={
                                    activeState.contents === "Questions"
                                        ? false
                                        : true
                                }
                            >
                                <Divider
                                    orientation="vertical"
                                    style={{
                                        marginRight: "32px",
                                        marginLeft: "20px",
                                    }}
                                />
                            </div>

                            <CustomModal
                                isOpen={openModal}
                                setIsOpenFunc={setOpenModal}
                                onOKFunc={modalFunction}
                            >
                                {modalBody}
                            </CustomModal>

                            <CustomModal
                                isOpen={isFinishedByMobile}
                                setIsOpenFunc={setIsFinishedByMobile}
                                onOKFunc={modalFunction}
                                isNotice={true}
                                onCloseFunc={modalFunction}
                            >
                                {finishedModalBody}
                            </CustomModal>

                            <CustomModal
                                isOpen={isScreenError}
                                setIsOpenFunc={setIsScreenError}
                                onOKFunc={modalFunction}
                                isNotice={true}
                                onCloseFunc={modalFunction}
                            >
                                {screenErrorModalBody}
                            </CustomModal>

                            <MediaQuery query="(max-width: 1200px)">
                                <Grid item xs={8}>
                                    {qeustionContents}
                                </Grid>
                            </MediaQuery>

                            <MediaQuery query="(min-width: 1200px)">
                                <Grid
                                    item
                                    sm={
                                        activeState.contents === "Questions"
                                            ? 6
                                            : 10
                                    }
                                    md={
                                        activeState.contents === "Questions"
                                            ? 7
                                            : 10
                                    }
                                    lg={
                                        activeState.contents === "Questions"
                                            ? 8
                                            : 10
                                    }
                                >
                                    {qeustionContents}
                                </Grid>
                            </MediaQuery>
                        </Grid>
                    )}
                </div>
            )}
        </Layout>
    );
}

function makeHiddenFlags(
    state: { examStateValue: Exam; questionsArray: ExpansionExamQuestions[] },
    activeItemKey: number
): hiddenFlags {
    const { startViewFlag, endViewFlag, pageMax } = state.examStateValue;
    return {
        start: !startViewFlag || (startViewFlag && activeItemKey !== 0),
        return:
            (startViewFlag && (activeItemKey === 0 || activeItemKey === 1)) ||
            (!startViewFlag && activeItemKey === 0),
        next:
            (startViewFlag && activeItemKey === 0) ||
            activeItemKey === pageMax - 1 ||
            (endViewFlag && activeItemKey === pageMax - 2),
        end: endViewFlag
            ? activeItemKey !== pageMax - 2
            : activeItemKey !== pageMax - 1,
    };
}
