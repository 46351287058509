import * as h from "history";
import { v4 as uuidv4 } from 'uuid';
import { IUseCommon } from './common';
import { APIArgs, APIResponse, ApiMethod, Exam, Question, learner, PostQuestionArgs } from "./react-app-env";

const CODE_SUCCESS = 20000;

/**
 * チュートリアル試験モード時にAPIを置き換えるフック
 * @param cmn 
 * @returns 
 */
export function useFakeApi(cmn: IUseCommon) {
    const { fake_data, fakeapi_mode } = cmn.appContext;
    if (!fake_data || !fakeapi_mode) {
        return cmn;
    }
    async function _backgroud_api<TArgs = APIArgs, TResponse = any>(path: string, method: ApiMethod, args?: TArgs) {
        const res = await fake_api(fake_data as any, path, method, args);
        cmn.setAppContext(c => {
            return {
                ...c,
                fake_data: fake_data
            };
        });
        return res;
    }
    async function _api<TArgs = APIArgs, TResponse = any>(path: string, method: ApiMethod, args?: TArgs) {
        const res = await cmn.withLoading(fake_api(fake_data as any, path, method, args));
        cmn.setAppContext(c => {
            return {
                ...c,
                fake_data: fake_data
            };
        });
        return res;
    }
    cmn.api = _api
    cmn.backgroud_api = _backgroud_api;
    cmn.go = fake_go.bind(null, cmn.go);
    return cmn;
}

/**
 * チュートリアル試験モード時の遷移を変換して実行する関数
 * @param go 
 * @param location 
 */
function fake_go(go: Function, location: h.LocationDescriptor<any>) {
    const orig_target = location.toString().trimEnd().toLowerCase();
    let target = orig_target;
    switch (orig_target) {
        case "/tutorial":
        case '/devicecheck':
        case '/pc/devicecheck':
        case '/mobile/devicecheck':
            // 変換しないケース
            break;
        case "/":
            // 個別に変換を指定するケース
            //target = "/tutorial";
            target = "/tutorial/home"; 
            break;
        default:
            // 二重に変換するのを回避
            if (orig_target.indexOf("/tutorial") != 0) {
                // 既定の変換
                target = "/tutorial" + orig_target;
            }
            break;
    }
    console.log("fake_go: from " + orig_target + " to " + target);
    go(target);
}

/**
 * チュートリアル試験モード時の実際には通信しないでエミュレーションするAPI関数
 * @param data 
 * @param path 
 * @param method 
 * @param _args 
 * @returns 
 */
async function fake_api<TArgs = APIArgs, TResponse = any>(
    data: { user: learner; exam: Exam; questions: Question[] },
    path: string, method: ApiMethod, _args?: TArgs): Promise<TResponse> {
    let value: any = null;
    const args = _args as any;

    // 試験中であれば、終了までの時間を更新する
    // 0:受験前 1:受験開始後 2:受験終了後
    if (data.exam.startStatus == 1) {
        const usrExamStart = new Date(data.user.executionStartDatetime);
        const usrExamLimit = new Date(usrExamStart.getTime());
        usrExamLimit.setMinutes(usrExamLimit.getMinutes() + data.exam.executeTime);
        const endSeconds = (usrExamLimit.getTime() - new Date().getTime()) / 1000;
        data.exam.endSeconds = endSeconds > 0 ? endSeconds : 0;
    }

    switch (path) {
        case "/api/l-exam":
            switch (method) {
                case "GET":
                    // getExam()
                    value = data.exam;
                    break;
                case "PUT":
                    // putExam()
                    if (args?.start) {
                        startExam(data.user, data.exam);
                    } else if (args?.end) {
                        endExam(data.user, data.exam);
                    }
                    value = null;
                    break;
            }
            break;
        case "/api/l-question":
            switch (method) {
                case "GET":
                    // getQuestion()
                    if (args?.page != undefined) {
                        value = data.questions.find(x => x.page == args.page);
                    } else if (args?.id != undefined) {
                        value = data.questions.find(x => x.id == args.id);
                    }
                    break;
                case "POST":
                    //解答を保存
                    // postQuestion()
                    if (args?.id != undefined) {

                        // 既読状態を更新
                        const eq = data.exam.questions.find(x => x.id == args.id);
                        if (eq) {
                            if ((args.choices && args.choices.length > 0) || args.answerText != "") {
                                eq.answerStatus = 2 // 回答済み
                            } else {
                                eq.answerStatus = 1 // 既読
                            }
                        }
                        
                        // 選択状態を更新
                        const q = data.questions.find(x => x.id == args.id);
                        if (q) setAnswer(q, args as PostQuestionArgs);

                        value = q;
                    }
                    break;
            }
            break;
        case "/api/l-learner":
            switch (method) {
                case "GET":
                    // getLearner()
                    value = data.user;
                    break;
            }
            break;
        case "/api/l-leave":
            switch (method) {
                case "POST":
                    // 退席状態更新
                    // postLeave()
                    data.user.leaveFlag = args.leave;
                    // 離席時に既読のものは変更不可
                    if (args.leave) {
                        data.exam.questions
                            .filter(q => q.answerStatus > 0) // 0:未読
                            .forEach(q => {
                                q.readonlyFlag = true;
                            });
                    }
                    break;
            }
            break;
    }
    console.log("======fake api call");
    console.log({
        req: { path, method, args },
        res: { data, value }
    });

    return {
        errorCode: CODE_SUCCESS,
        errorTitle: "",
        errorDetail: "",
        value: value
    } as APIResponse<any> as unknown as TResponse;
}

/**
 * 試験開始処理
 * @param user 
 * @param exam 
 */
function startExam(user: learner, exam: Exam) {
    // 任意試験のユーザー受験開始時刻
    const usrExamStart = new Date();
    // 受験終了期限　受験開始時刻＋試験時間
    const usrExamLimit = new Date(usrExamStart.getTime());
    usrExamLimit.setMinutes(usrExamLimit.getMinutes() + exam.executeTime);

    // 受験開始状態
    // 0:受験前 1:受験開始後 2:受験終了後
    exam.startStatus = 1;
    // 試験開始までの秒数 開始したので0に
    exam.startSeconds = 0;
    // 終了までの秒数　試験の終わりではなくて、examLimitまで
    exam.endSeconds = (usrExamLimit.getTime() - usrExamStart.getTime()) / 1000;
    user.executionStartDatetime = usrExamStart?.toISOString();
    user.startStatus = exam.startStatus;
}

/**
 * 試験終了処理
 * @param user 
 * @param exam 
 */
function endExam(user: learner, exam: Exam) {
    // 任意試験のユーザー受験終了時刻
    const usrExamEnd = new Date();
    // 受験開始状態
    // 0:受験前 1:受験開始後 2:受験終了後
    exam.startStatus = 2;
    // 終了までの秒数 終了したので0に
    exam.endSeconds = 0;
    user.executionEndDatetime = usrExamEnd.toISOString();
    user.startStatus = exam.startStatus;
}

/**
 * 解答保存処理
 * @param question
 * @param answer 
 */
function setAnswer(question: Question, answer: PostQuestionArgs) {

    // 解答種別ごとに保存
    if (question.answerType > 1 && answer.answerText != "") {
        question.answerText = answer.answerText;

    } else if (question.answerType == 0 && answer.choices.length > 0) {
        question.choices.forEach(x => x.selected = answer.choices.find(y => y.choiceId == x.id) != undefined);

    } else if (question.answerType == 1 && answer.choices.length > 0) {
        question.choices.forEach(x => x.selected = answer.choices.find(y => y.choiceId == x.id) != undefined);

    }
}

/**
 * 模擬データの初期値を生成
 * @returns 
 */
export function initFakeData() {

    const now = new Date();

    // 試験期間開始日時
    // 昨日開始設定
    const examStart = new Date(now.getTime());
    examStart.setDate(now.getDate() - 1);

    //試験期間終了日時
    // 明日終了設定
    const examEnd = new Date(now.getTime());
    examEnd.setDate(now.getDate() + 1);

    // 試験時間：60分
    const examTime = 60;
    const startViewFlag = true;
    const endViewFlag = true;
    const choiceDeviceFlag = true; // true : モバイル分離

    // 設問データ
    const questions = [
        {
            "id": 15,
            "sectionFlag": true,
            "parentQuestionId": 0,
            "parentText": null as unknown as string,
            "parentHtml": null as unknown as string,
            "subjectText": "問題セクションの説明文です。",
            "bodyHtml": "{\"blocks\":[{\"key\":\"7029q\",\"text\":\"問題セクションの説明文です。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
            "answerType": 0,
            "choiceShuffleFlag": false,
            "choiceNumberingType": 0,
            "choices": [],
            "answerText": "",
            "figures": null as unknown,
            "page": 1,
            "pageMax": 7
        } as Question,
        {
            "id": 16,
            "sectionFlag": false,
            "parentQuestionId": 15,
            "parentText": "問題セクションの説明文です。",
            "parentHtml": "{\"blocks\":[{\"key\":\"7029q\",\"text\":\"問題セクションの説明文です。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
            "subjectText": "択一選択肢の問題例です。\n正しい解答をひ",
            "bodyHtml": "{\"blocks\":[{\"key\":\"b0g55\",\"text\":\"択一選択肢の問題例です。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cg5p1\",\"text\":\"正しい解答をひとつ選択します。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
            "answerType": 0,
            "choiceShuffleFlag": false,
            "choiceNumberingType": 0,
            "choices": [
                {
                    "id": 26,
                    "viewOrder": 1,
                    "bodyText": "選択肢１",
                    "selected": false
                },
                {
                    "id": 25,
                    "viewOrder": 2,
                    "bodyText": "選択肢２",
                    "selected": false
                }
            ],
            "answerText": "",
            "figures": null as unknown,
            "page": 2,
            "pageMax": 7
        } as Question,
        {
            "id": 17,
            "sectionFlag": false,
            "parentQuestionId": 15,
            "parentText": "問題セクションの説明文です。",
            "parentHtml": "{\"blocks\":[{\"key\":\"7029q\",\"text\":\"問題セクションの説明文です。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
            "subjectText": "複数回答の問題例です。\n正しい選択肢をす",
            "bodyHtml": "{\"blocks\":[{\"key\":\"13aua\",\"text\":\"複数回答の問題例です。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"201ff\",\"text\":\"正しい選択肢をすべて選択します。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
            "answerType": 1,
            "choiceShuffleFlag": false,
            "choiceNumberingType": 0,
            "choices": [
                {
                    "id": 24,
                    "viewOrder": 1,
                    "bodyText": "選択肢１",
                    "selected": false
                },
                {
                    "id": 27,
                    "viewOrder": 2,
                    "bodyText": "選択肢２",
                    "selected": false
                },
                {
                    "id": 28,
                    "viewOrder": 3,
                    "bodyText": "選択肢３",
                    "selected": false
                }
            ],
            "answerText": "",
            "figures": null as unknown,
            "page": 3,
            "pageMax": 7
        } as Question,
        {
            "id": 18,
            "sectionFlag": false,
            "parentQuestionId": 0,
            "parentText": null as unknown,
            "parentHtml": null as unknown,
            "subjectText": "記述回答（半角数字）の問題例です。\n半角数字を入力",
            "bodyHtml": "{\"blocks\":[{\"key\":\"13aua\",\"text\":\"記述回答（半角数字）の問題例です。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"201ff\",\"text\":\"半角数字を入力します。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
            "answerType": 2,
            "choiceShuffleFlag": false,
            "choiceNumberingType": 0,
            "choices": [{}],
            "answerText": "",
            "figures": null as unknown,
            "page": 4,
            "pageMax": 7
        } as Question,
        {
            "id": 19,
            "sectionFlag": false,
            "parentQuestionId": 0,
            "parentText": null as unknown,
            "parentHtml": null as unknown,
            "subjectText": "記述回答（文字）の問題例です。\n文字を入力",
            "bodyHtml": "{\"blocks\":[{\"key\":\"13aua\",\"text\":\"記述回答（文字）の問題例です。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"201ff\",\"text\":\"文字を入力します。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
            "answerType": 3,
            "choiceShuffleFlag": false,
            "choiceNumberingType": 0,
            "choices": [{}],
            "answerText": "",
            "figures": null as unknown,
            "page": 5,
            "pageMax": 7
        } as Question
    ];

    // 試験データ
    const exam = {
        "name": "試験操作チュートリアル",
        "status": 1,// 0:下書き
        "termType": 1,// 0:一斉、1:任意
        "shuffleFlag": false,
        /*
            case 0: type = "decimal"; break;
            case 1: type = "katakana"; break;
            case 2: type = "lower-roman"; break;
            case 3: type = "upper-roman"; break;
        */
        "numberingType": 0, // 数字
        acceptStart: examStart.toISOString(),
        acceptEnd: examStart.toISOString(),
        executeStart: examStart.toISOString(),// 試験期間開始日時
        executeEnd: examEnd.toISOString(),// 試験期間終了日時
        "executeTime": examTime,// 試験時間（分）
        "learnerMax": 1,// 最大受験人数
        "guidanceUrl": "",
        "startViewFlag": startViewFlag,
        "startBodyHtml": "{\"blocks\":[{\"key\":\"7v3cq\",\"text\":\"これは、試験操作チュートリアルの開始画面です。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
        "endViewFlag": endViewFlag,
        "endBodyHtml": "{\"blocks\":[{\"key\":\"8uu71\",\"text\":\"これは、試験操作チュートリアルの終了画面です。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
        "allPoints": 100,
        "passPoints": 0,
        "choiceDeviceFlag": choiceDeviceFlag, // true : モバイル分離
        "afterReadFlag": true,
        "questionNum": 4, // 問題数
        "pageMax": 7,
        // 受験開始状態
        // 0:受験前 1:受験開始後 2:受験終了後
        "startStatus": 0,
        "startSeconds": 0,
        "endSeconds": 0,
        "advanceExplanationVideoUrl": "",
        "questions": [
            {
                "id": 15,
                "page": 1,
                "sectionFlag": true,
                "parentQuestionId": 0,
                "subjectText": "問題セクションの説明文です。",
                "answerStatus": 0,
                "readonlyFlag": false
            },
            {
                "id": 16,
                "page": 2,
                "sectionFlag": false,
                "parentQuestionId": 15,
                "subjectText": "択一選択肢の問題例です。\n正しい解答をひ",
                "answerStatus": 0,
                "readonlyFlag": false
            },
            {
                "id": 17,
                "page": 3,
                "sectionFlag": false,
                "parentQuestionId": 15,
                "subjectText": "複数回答の問題例です。\n正しい選択肢をす",
                "answerStatus": 0,
                "readonlyFlag": false
            },
            {
                "id": 18,
                "page": 4,
                "sectionFlag": false,
                "parentQuestionId": 0,
                "subjectText": "記述回答（半角数字）の問題例です。\n半角数字を入力",
                "answerStatus": 0,
                "readonlyFlag": false
            },
            {
                "id": 19,
                "page": 5,
                "sectionFlag": false,
                "parentQuestionId": 0,
                "subjectText": "記述回答（文字）の問題例です。\n文字を入力",
                "answerStatus": 0,
                "readonlyFlag": false
            }
        ],
        "extensions": [
            {
                "code": "env_record",
                "name": "受験会場撮影",
                "extensionType": 0,
                "parameter": 1
            },
            {
                "code": "leave",
                "name": "途中退席",
                "extensionType": 0,
                "parameter": 1
            },
            {
                "code": "prebrief",
                "name": "前説再生",
                "extensionType": 0,
                "parameter": 1
            },
            {
                "code": "record",
                "name": "受験動画撮影",
                "extensionType": 0,
                "parameter": 1
            },
            {
                "code": "self_photo",
                "name": "本人写真撮影",
                "extensionType": 0,
                "parameter": 1
            }
        ]
    } as Exam;

    // 受験者データ
    const user = {

        executionName: exam.name,
        executeStart: exam.executeStart,
        executeEnd: exam.executeEnd,
        executeTime: exam.executeTime,
        termType: exam.termType,
        questionNum: exam.questionNum,

        learnerNumber: "000001",
        executionStartDatetime: null as unknown,
        executionEndDatetime: null as unknown,
        startStatus: exam.startStatus,
        leaveFlag: false,
        loginCode: uuidv4(), // 試験受け付けコード
        hasPanoramaVideo: true,
        hasPortrait: true,
        deviceCheckIdPC: uuidv4(),
        deviceCheckIdMobile: uuidv4(),
        executionPCIP: "0",
        executionSPIP: "0"
    } as learner;

    return {
        user,
        exam,
        questions
    };
}


